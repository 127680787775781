import './header.css';
import React, { useEffect, useState, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import UserDropdown from '../logout/logout';
import NotificationDropdown from '../notification/notification'

export default function Header(props) {
    const [decodedToken, setDecodedToken] = useState(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const arrowRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('token_aos');

        if (token) {
            const decodedToken = jwtDecode(token);
            setDecodedToken(decodedToken);
        }
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <div className="main-container-header">
                <img loading="lazy" alt="Loading1" src="aos.png" className="main-image" />
                <div className="image-wrapper-header">
                    <NotificationDropdown />
                    <div
                        onClick={toggleDropdown}
                        className="user-name1"
                        ref={arrowRef}
                    >{`${decodedToken?.user?.firstName?.substring(0, 1).toUpperCase()}${decodedToken?.user.lastName.substring(0, 1).toUpperCase()}` || 'UN'}</div>
                    <div className="user-name">{`${decodedToken?.user.firstName} ${decodedToken?.user.lastName}` || 'User Name'}</div>
                    <UserDropdown />
                </div>
            </div>
        </>
    );
}
