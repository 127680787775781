import React, { useState, useEffect } from "react";
import axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router-dom';
import UserDropdown from "../userDropDown/userDropdown"
import StatusDropdown from "../statusDropdown/statusDropdown"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { jwtDecode } from 'jwt-decode';
import './userPopup.css'

export default function Modal({ buttonComponent, data, showData }) {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [code, setCode] = useState('');
    const [userError, setUserError] = useState('');

    // State to manage input values
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('user'); // Default to 'user', you can change it as needed


    useEffect(() => {
        console.log('Modal')
        if (submitted) {
            console.log('Modal11111111111')
            showData()
            setSubmitted(false)
        }
    }, [modal]);


    const handleInputChange = (e, inputType) => {
        const value = e.target.value;

        switch (inputType) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'userType':
                setUserType(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let userType1 = userType
        if (!userType || userType === '') {
            userType1 = 'user';
        }
        const apiUrl = `https://www.aos.fit/api/createuser`;
        const requestBody = {
            firstName,
            lastName,
            email,
            userType: userType1
        };

        let response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });
        const result = await response.json();
        console.log(result)
        if (result.error) {
            return setUserError(true)
        }
        setCode(result?.user?._id)
        // Perform further actions, e.g., send data to the server
        console.log('Form submitted:', result);
        setFirstName('')
        setLastName('')
        setEmail('')
        setUserType('')
        setSubmitted(true)
    };

    const handleClose = () => {
        setModal(!modal);
        setSubmitted(false)
    }
    const toggleModal = () => {
        if (!modal) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        setUserError(false)
        setModal(!modal);
        // showPop1(false)
    };


    if (modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    return (
        <>
            {buttonComponent && buttonComponent(toggleModal)}

            {modal && (
                <div className="modal">
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="modal-content2">
                        <div className="divup">
                            {!submitted && (
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between", 'width': "100%" }}>
                                        <div className="divup-2">Basic Information</div>
                                        <img loading="lazy" src="close.svg" className="close" onClick={handleClose}></img>

                                    </div>
                                    <form onSubmit={handleSubmit} className="divup-3">
                                        <div className="divup-4">
                                            <div className="divup-5">
                                                <div className="divup-6">First Name</div>
                                                <input
                                                    className="divup-7"
                                                    type="text"
                                                    value={firstName}
                                                    minLength={3} // Minimum length requirement
                                                    maxLength={15} // Maximum length requirement
                                                    required
                                                    onChange={(e) => handleInputChange(e, 'firstName')}
                                                />
                                            </div>

                                            <div className="divup-8">
                                                <div className="divup-9">Second Name</div>
                                                <input
                                                    className="divup-7"
                                                    type="text"
                                                    value={lastName}
                                                    minLength={3}
                                                    maxLength={15}
                                                    required
                                                    onChange={(e) => handleInputChange(e, 'lastName')}
                                                />
                                            </div>
                                        </div>
                                        <div className="divup-11">Email </div>
                                        <input
                                            className="divup-7"
                                            type="email"
                                            value={email}
                                            required
                                            onChange={(e) => handleInputChange(e, 'email')}
                                        />
                                        {userError && (<div className="email-error"> Email Already Exists</div>)}
                                        <div className="divup-13">User Type</div>
                                        <select className="divup-14" value={userType} required onChange={(e) => handleInputChange(e, 'userType')}>
                                            <option value="user">User</option>
                                            <option value="moderator">Moderator</option>
                                        </select>
                                        <button className="divup-16" type="submit" >Send Email</button>
                                    </form>
                                </>
                            )}
                            {submitted && (
                                <div className="email-confirmation">
                                    <div className="close-info">
                                        <div className="user-info">
                                            <div className="user-code">User Code</div>
                                            <div className="user-phone">{code}</div>
                                        </div>
                                        <img
                                            loading="lazy"
                                            src="close.svg"
                                            onClick={handleClose}
                                            className="logo"
                                        />
                                    </div>
                                    <div className="divider" />
                                    <img
                                        loading="lazy"
                                        src="emailsend.svg"
                                        className="imagepop"
                                    />
                                    <div className="confirmation-msg">
                                        <h1 className="">Email Has Sent</h1>
                                        <h2 className="">Successfully To The User</h2>
                                    </div>
                                    <button onClick={handleClose} className="confirm-btn">Confirm</button>
                                </div>)}
                        </div>
                    </div >
                </div >
            )}
        </>
    );
}




