import React, { useState, useEffect } from 'react';
import './ramadan.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Switch from '@mui/material/Switch';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function MyComponent(props) {
    const [imageGardenUrl, setImageGardenUrl] = useState('');
    const [gardenName, setGardenName] = useState(null)
    const [garden, setGarden] = useState(false);

    const [imageYoussefUrl, setImageYoussefUrl] = useState('');
    const [youssefName, setYoussefName] = useState(null)
    const [youssef, setYoussef] = useState(false);


    const [imageGymUrl, setImageGymUrl] = useState('');
    const [gymName, setGymName] = useState(null)
    const [gym, setGym] = useState(false);

    const [imageJumpUrl, setImageJumpUrl] = useState('');
    const [jumpName, setJumpName] = useState(null)
    const [jump, setJump] = useState(false);

    const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
    const [assesmentName, setAssesmentName] = useState(null)
    const [assesment, setAssesment] = useState(false);

    const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
    const [allPeopleName, setAllPeopleName] = useState(null)
    const [allPeople, setAllPeople] = useState(false);

    const [imageGym1Url, setImageGym1Url] = useState('');
    const [gym1Name, setGym1Name] = useState(null)
    const [gym1, setGym1] = useState(false);

    const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
    const [crazyOffer1Name, setCrazyOffer1Name] = useState(null)
    const [crazyOffer1, setCrazyOffer1] = useState(false);

    const [imageReviews1Url, setImageReviews1Url] = useState('');
    const [reviews1Name, setReviews1Name] = useState(null)
    const [reviews1, setReviews1] = useState(false);

    const [youtubeUrl1, setYoutubeUrl1] = useState('');
    const [youtubeUrlt1, setYoutubeUrlt1] = useState('');
    const [isValid1, setIsValid1] = useState(true);

    const [youtubeUrl2, setYoutubeUrl2] = useState('');
    const [youtubeUrlt2, setYoutubeUrlt2] = useState('');
    const [isValid2, setIsValid2] = useState(true);

    const [youtubeUrl3, setYoutubeUrl3] = useState('');
    const [youtubeUrlt3, setYoutubeUrlt3] = useState('');
    const [isValid3, setIsValid3] = useState(true);

    const [youtubeUrl4, setYoutubeUrl4] = useState('');
    const [youtubeUrlt4, setYoutubeUrlt4] = useState('');
    const [isValid4, setIsValid4] = useState(true);

    const [youtubeUrl5, setYoutubeUrl5] = useState('');
    const [youtubeUrlt5, setYoutubeUrlt5] = useState('');
    const [isValid5, setIsValid5] = useState(true);

    const [enteredSpot, setEnteredSpot] = useState(0);
    const [enteredPercentage, setEnteredPercentage] = useState(0);
    const [spot, setSpot] = useState(false);
    const [percentage, setPercentage] = useState(false);
    const [selectedGymDate, setSelectedGymDate] = useState(null);
    const [selectedGymTime, setSelectedGymTime] = useState(null);
    const [gymDay, setGymDay] = useState(0);
    const [gymHours, setGymHours] = useState(0);
    const [gymMinutes, setGymMinutes] = useState(0);
    const [timing, setTiming] = useState(false);
    const [wholeDate, setWholeDate] = useState(null);
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl1 = `https://www.aos.fit/api/getalldata`;
                let allData = await fetch(apiUrl1);
                const allDataResult = await allData.json();
                setEnteredSpot(allDataResult['ramadanSpot'])
                setEnteredPercentage(allDataResult['gymPercentage'])
                setImageYoussefUrl(`https://www.aos.fit/api/public/${allDataResult['ramadan5']}`)
                setImageGardenUrl(`https://www.aos.fit/api/public/${allDataResult['youssefRamadan']}`)
                setImageGymUrl(`https://www.aos.fit/api/public/${allDataResult['ramadan6']}`)
                setImageAssesmentUrl(`https://www.aos.fit/api/public/${allDataResult['ramadan3']}`)
                setImageAllPeopleUrl(`https://www.aos.fit/api/public/${allDataResult['ramadan1']}`)
                setImageGym1Url(`https://www.aos.fit/api/public/${allDataResult['ramadan4']}`)
                setImageJumpUrl(`https://www.aos.fit/api/public/${allDataResult['ramadan2']}`)
                setImageCrazyOffer1Url(`https://www.aos.fit/api/public/${allDataResult['ramadancrazyOffer1']}`)
                setImageReviews1Url(`https://www.aos.fit/api/public/${allDataResult['ramadanreviews1']}`)
                setYoutubeUrl1(`${allDataResult['ramadanyoutubeUrl1']}`)
                setYoutubeUrl2(`${allDataResult['ramadanyoutubeUrl2']}`)
                setYoutubeUrl3(`${allDataResult['ramadanyoutubeUrl3']}`)
                setYoutubeUrl4(`${allDataResult['ramadanyoutubeUrl4']}`)
                setYoutubeUrl5(`${allDataResult['ramadanyoutubeUrl5']}`)
                setGymDay(allDataResult['ramadanDay'])
                setGymHours(allDataResult['ramadanHour'])
                setGymMinutes(allDataResult['ramadanMinute'])
                setChecked(!allDataResult['ramadanClosed'])
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

    }, []);
    const validateYouTubeUrl = async (event, type) => {
        const url = event.target.value;

        if (type === 'youtubeUrl1') setYoutubeUrlt1(url)
        else if (type === 'youtubeUrl2') setYoutubeUrlt2(url)
        else if (type === 'youtubeUrl3') setYoutubeUrlt3(url)
        else if (type === 'youtubeUrl4') setYoutubeUrlt4(url)
        else if (type === 'youtubeUrl5') setYoutubeUrlt5(url)
        if (url !== undefined || url !== '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length === 11) {
                console.log(type, 'youtubeUrlType')
                if (type === 'youtubeUrl1') {
                    setIsValid1('ready')
                    setYoutubeUrl1('https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1')
                } else if (type === 'youtubeUrl2') {
                    setYoutubeUrl2('https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1')
                    setIsValid2('ready')
                } else if (type === 'youtubeUrl3') {
                    setYoutubeUrl3('https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1')
                    setIsValid3('ready')
                } else if (type === 'youtubeUrl4') {
                    setYoutubeUrl4('https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1')
                    setIsValid4('ready')
                } else if (type === 'youtubeUrl5') {
                    setYoutubeUrl5('https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1')
                    setIsValid5('ready')
                }
            } else {
                if (type === 'youtubeUrl1') setIsValid1('invalid')
                if (type === 'youtubeUrl2') setIsValid2('invalid')
                if (type === 'youtubeUrl3') setIsValid3('invalid')
                if (type === 'youtubeUrl4') setIsValid4('invalid')
                if (type === 'youtubeUrl5') setIsValid5('invalid')
            }
        }
    }

    const handleClose = async (event) => {

        if (event.target.checked) {
            const response1 = await fetch(`https://www.aos.fit/api/setData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: false, type: 'ramadanClosed' })
            })
            toast('Page is now visible', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: { backgroundColor: 'green', color: 'white' },
                theme: "light",
            });
        } else {
            const response1 = await fetch(`https://www.aos.fit/api/setData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: true, type: 'ramadanClosed' })
            })
            toast('Page will be hidden', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: { backgroundColor: 'green', color: 'white' },
                theme: "light",
            });
        }
        setChecked(event.target.checked ? false : true);
    };

    const saveImage = async (event, type) => {
        let fileName = 'garden'
        const formData = new FormData();
        if (type === 'garden') {
            fileName = 'youssefRamadan'
            if (gardenName) {
                formData.append('file', gardenName);
                setGarden(true)
            }
        }
        if (type === 'youssef') {
            fileName = 'ramadan5'
            if (youssefName) {
                formData.append('file', youssefName);
                setYoussef(true)
            }
        }
        if (type === 'gym') {
            fileName = 'ramadan6'
            if (gymName) {
                formData.append('file', gymName);
                setGym(true)
            }
        }
        if (type === 'jump') {
            fileName = 'ramadan2'
            if (jumpName) {
                formData.append('file', jumpName);
                setJump(true)
            }
        }
        if (type === 'gym1') {
            fileName = 'ramadan4'
            if (gym1Name) {
                formData.append('file', gym1Name);
                setGym1(true)
            }
        }
        if (type === 'allPeople') {
            fileName = 'ramadan1'
            if (allPeopleName) {
                formData.append('file', allPeopleName);
                setAllPeople(true)
            }
        }
        if (type === 'assesment') {
            fileName = 'ramadan3'
            if (assesmentName) {
                formData.append('file', assesmentName);
                setAssesment(true)
            }
        }
        if (type === 'crazyOffer1') {
            fileName = 'ramadancrazyOffer1'
            if (crazyOffer1Name) {
                formData.append('file', crazyOffer1Name);
                setCrazyOffer1(true)
            }
        }
        if (type === 'reviews1') {
            fileName = 'ramadanreviews1'
            if (reviews1Name) {
                formData.append('file', reviews1Name);
                setReviews1(true)
            }
        }


        const response = await axios.post(`https://www.aos.fit/api/setmedia?filename=${fileName}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        console.log(response.data);
        if (response.ok) {
            console.log('Media uploaded successfully');
        } else {
            // console.error('Media upload failed');
        }
    }
    const handleGymDateChange = (date) => {
        if (date) {
            const currentDate = new Date();
            const differenceInMilliseconds = date - currentDate;
            let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            let hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            const timestamp1 = date.getTime();
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay(days)
            setGymHours(hours)
            setGymMinutes(minutes)
            setSelectedGymDate(date)
            setWholeDate(timestamp1)
        }
    }
    const handleGymTimeChange = (date) => {
        const dateTemp = selectedGymDate || new Date();
        console.log('handleGymTimeChange')
        console.log(date.$H)
        console.log(date.$m)
        console.log(dateTemp)
        console.log(typeof date.$H, typeof date.$m)
        if (typeof date.$H === 'number' && typeof date.$m === 'number') {
            console.log('handleGymTimeChangeInside')
            const currentDate = new Date();
            const timestamp1 = dateTemp.getTime();
            console.log('timestamp1', timestamp1)
            const differenceInMilliseconds = dateTemp - currentDate;
            const additionalHoursMilliseconds = date.$H * 60 * 60 * 1000;
            const subtractedHoursMilliseconds = date.$m * 60 * 1000;
            const addTime = differenceInMilliseconds + additionalHoursMilliseconds + subtractedHoursMilliseconds
            let days = Math.floor(addTime / (1000 * 60 * 60 * 24));
            let hours = Math.floor((addTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((addTime % (1000 * 60 * 60)) / (1000 * 60));
            console.log(days, hours, minutes)
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay(days)
            setGymHours(hours)
            setGymMinutes(minutes)
            setWholeDate(timestamp1 + additionalHoursMilliseconds + subtractedHoursMilliseconds)
        }
    };

    const handleSpotChange = (event) => {
        let value = event.target.value;
        if (value > 10000) {
            value = 10000
        }
        setEnteredSpot(value);
    };

    const handlePercentageChange = (event) => {
        let value = event.target.value;
        if (value > 100) {
            value = 100
        }
        setEnteredPercentage(value);
    };

    const saveSpot1 = async () => {
        saveSpot(gymDay, 'ramadanDay')
        saveSpot(gymHours, 'ramadanHour')
        saveSpot(gymMinutes, 'ramadanMinute')
        saveSpot(JSON.stringify(wholeDate), 'ramadanTimestamp')
        setTiming(true)
    }

    const saveSpot = async (data, type) => {

        if (type === 'gymPercentage') setPercentage(true)
        if (type === 'ramadanSpot') setSpot(true)
        if (type === 'ramadanyoutubeUrl1') {
            if (isValid1 == 'ready') {
                setIsValid1('submitted')
            } else {
                return setIsValid1('invalid')
            }
        }
        if (type === 'ramadanyoutubeUrl2') {
            if (isValid2 == 'ready') {
                setIsValid2('submitted')
            } else {
                return setIsValid2('invalid')
            }
        }
        if (type === 'ramadanyoutubeUrl3') {
            if (isValid3 == 'ready') {
                setIsValid3('submitted')
            } else {
                return setIsValid3('invalid')
            }
        }
        if (type === 'ramadanyoutubeUrl4') {
            if (isValid4 == 'ready') {
                setIsValid4('submitted')
            } else {
                return setIsValid4('invalid')
            }
        }
        if (type === 'ramadanyoutubeUrl5') {
            if (isValid5 == 'ready') {
                setIsValid5('submitted')
            } else {
                return setIsValid5('invalid')
            }
        }
        const response1 = await fetch(`https://www.aos.fit/api/setData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: data, type: type })
        })

    }

    const uploadMedia = async (event, type) => {
        try {
            const file = event.target.files[0];

            console.log(event, type)
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {

                    if (type === 'garden') {
                        setGardenName(file)
                        setImageGardenUrl(reader.result);
                    }
                    if (type === 'youssef') {
                        setYoussefName(file)
                        setImageYoussefUrl(reader.result)
                    }
                    if (type === 'gym') {
                        setImageGymUrl(reader.result)
                        setGymName(file)
                    }
                    if (type === 'gym1') {
                        setImageGym1Url(reader.result)
                        setGym1Name(file)
                    }
                    if (type === 'jump') {
                        setImageJumpUrl(reader.result)
                        setJumpName(file)
                    }
                    if (type === 'assesment') {
                        setImageAssesmentUrl(reader.result)
                        setAssesmentName(file)
                    }
                    if (type === 'allPeople') {
                        setImageAllPeopleUrl(reader.result)
                        setAllPeopleName(file)
                    }
                    if (type === 'crazyOffer1') {
                        setImageCrazyOffer1Url(reader.result)
                        setCrazyOffer1Name(file)
                    }
                    if (type === 'reviews1') {
                        setImageReviews1Url(reader.result)
                        setReviews1Name(file)
                    }
                };
                reader.readAsDataURL(file);
            }
        } catch (error) {
            console.error('Error uploading media:', error);
            // Handle error if needed
        }
    };

    return (
        <>
            <div className="divba">
                <div className="divba-202"> <span>
                    Ramadan  Program Content Editor
                </span>
                    <Switch checked={checked} onChange={handleClose} />
                </div>
                <br />
                <div className="divba-2">Section One</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <div className="divba-505">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Video -1  (YouTube Link)</div>
                                                    <input
                                                        className="divba-11"
                                                        type="text"
                                                        onChange={(e) => validateYouTubeUrl(e, 'youtubeUrl5')}
                                                        value={youtubeUrlt5}
                                                    />
                                                    {isValid5 === 'invalid' && <div className="divba-e">link is not valid</div>}
                                                    {isValid5 === 'submitted' && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveSpot(youtubeUrl5, 'ramadanyoutubeUrl5') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-304">
                                                <iframe src={youtubeUrl5}
                                                    className="imgba-2" allowFullScreen="" wmode="opaque" id="fitvid219531" style={{ border: "none" }}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba-4">
                                <div className="divba-13">
                                    <div className="divba-14">
                                        <div className="columnba-5">
                                            <div className="divba-15">
                                                <div className="divba-16">Section One Countdown</div>
                                                <div className="divba-17">
                                                    <div className="divba-18">
                                                        <div className="divba-19">Set Date</div>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Basic date picker"
                                                                value={selectedGymDate}
                                                                onChange={handleGymDateChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>

                                                    <div className="divba-22">
                                                        <div className="divba-23">Set Time</div>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                value={selectedGymTime}
                                                                onChange={handleGymTimeChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                label="Basic time picker" />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                                {timing && <div className="divba-p"> data changed successfully</div>}
                                                <div className="divba-27" onClick={saveSpot1}>Save Changes</div>
                                            </div>
                                        </div>
                                        <div className="columnba-6">
                                            <div className="divba-28">
                                                <div className="divba-29">
                                                    <div className="divba-30">
                                                        <div className="divba-31">{gymDay}</div>
                                                        <div className="divba-32">Day</div>
                                                    </div>
                                                    <div className="divba-33">
                                                        <div className="divba-34">{gymHours}</div>
                                                        <div className="divba-35">Hour</div>
                                                    </div>
                                                    <div className="divba-36">
                                                        <div className="divba-37">{gymMinutes}</div>
                                                        <div className="divba-38">Minute</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="divba-4">
                        <div className="divba-5">
                            <div className='columnba'>
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Section One Available Spots </div>
                                                    <div className="divba-43"># of Spots Available</div>
                                                    <input className="divba-11" type="number" min={0} max={9999} onChange={handleSpotChange} default={0} value={enteredSpot} />
                                                    {spot && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-45" value="spots" onClick={() => { saveSpot(enteredSpot, 'ramadanSpot') }} >Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <div className="divba-46">
                                                    <div className="divba-47">ONLY</div>
                                                    <div className="divba-48">{enteredSpot} SPOTS &nbsp;</div>
                                                    <div className="divba-49">AVAILABLE</div>
                                                    <div className="divba-50"> !&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="divba-2">Section Four</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <div className="divba-5">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Upload Image 1</div>
                                                    <input
                                                        className="divba-11"
                                                        type="file"
                                                        id="mediaUpload"
                                                        name="mediaUpload"
                                                        accept="image/*"
                                                        onChange={(e) => { uploadMedia(e, 'allPeople') }}
                                                    />
                                                    {allPeople && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveImage(e, 'allPeople') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <img
                                                    loading="lazy"
                                                    src={imageAllPeopleUrl}
                                                    className="imgba-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Upload Image 2</div>
                                                    <input
                                                        className="divba-11"
                                                        type="file"
                                                        id="mediaUpload"
                                                        name="mediaUpload"
                                                        accept="image/*"
                                                        onChange={(e) => { uploadMedia(e, 'jump') }}
                                                    />
                                                    {jump && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveImage(e, 'jump') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <img
                                                    loading="lazy"
                                                    src={imageJumpUrl}
                                                    className="imgba-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="divba-4">
                        <div className="divba-5">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Upload Image 3</div>
                                                    <input
                                                        className="divba-11"
                                                        type="file"
                                                        id="mediaUpload"
                                                        name="mediaUpload"
                                                        accept="image/*"
                                                        onChange={(e) => { uploadMedia(e, 'assesment') }}
                                                    />
                                                    {assesment && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveImage(e, 'assesment') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <img
                                                    loading="lazy"
                                                    src={imageAssesmentUrl}
                                                    className="imgba-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Upload Image 4</div>
                                                    <input
                                                        className="divba-11"
                                                        type="file"
                                                        id="mediaUpload"
                                                        name="mediaUpload"
                                                        accept="image/*"
                                                        onChange={(e) => { uploadMedia(e, 'gym1') }}
                                                    />
                                                    {gym1 && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveImage(e, 'gym1') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <img
                                                    loading="lazy"
                                                    src={imageGym1Url}
                                                    className="imgba-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="divba-4">
                        <div className="divba-5">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Upload Image 5</div>
                                                    <input
                                                        className="divba-11"
                                                        type="file"
                                                        id="mediaUpload"
                                                        name="mediaUpload"
                                                        accept="image/*"
                                                        onChange={(e) => { uploadMedia(e, 'youssef') }}
                                                    />
                                                    {youssef && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveImage(e, 'youssef') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <img
                                                    loading="lazy"
                                                    src={imageYoussefUrl}
                                                    className="imgba-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Upload Image 6</div>
                                                    <input
                                                        className="divba-11"
                                                        type="file"
                                                        id="mediaUpload"
                                                        name="mediaUpload"
                                                        accept="image/*"
                                                        onChange={(e) => { uploadMedia(e, 'gym') }}
                                                    />
                                                    {gym && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveImage(e, 'gym') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <img
                                                    loading="lazy"
                                                    src={imageGymUrl}
                                                    className="imgba-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>

                <br />
                <div className="divba-2">Section Five</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <div className="divba-5">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Video (YouTube Link)</div>
                                                    <input
                                                        className="divba-11"
                                                        type="text"
                                                        onChange={(e) => validateYouTubeUrl(e, 'youtubeUrl1')}
                                                        value={youtubeUrlt1}
                                                    />
                                                    {isValid1 === 'invalid' && <div className="divba-e">link is not valid</div>}
                                                    {isValid1 === 'submitted' && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveSpot(youtubeUrl1, 'ramadanyoutubeUrl1') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-304">
                                                <iframe src={youtubeUrl1}
                                                    className="imgba-2" allowFullScreen="" wmode="opaque" id="fitvid219531" style={{ border: "none" }}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Video -2  (YouTube Link)</div>
                                                    <input
                                                        className="divba-11"
                                                        type="text"
                                                        onChange={(e) => validateYouTubeUrl(e, 'youtubeUrl2')}
                                                        value={youtubeUrlt2}
                                                    />
                                                    {isValid2 === 'invalid' && <div className="divba-e">link is not valid</div>}
                                                    {isValid2 === 'submitted' && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveSpot(youtubeUrl2, 'ramadanyoutubeUrl2') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-304">
                                                <iframe src={youtubeUrl2}
                                                    className="imgba-2" allowFullScreen="" wmode="opaque" id="fitvid219531" style={{ border: "none" }}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="divba-4">
                        <div className="divba-5">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Video -3  (YouTube Link)</div>
                                                    <input
                                                        className="divba-11"
                                                        type="text"
                                                        onChange={(e) => validateYouTubeUrl(e, 'youtubeUrl3')}
                                                        value={youtubeUrlt3}
                                                    />
                                                    {isValid3 === 'invalid' && <div className="divba-e">link is not valid</div>}
                                                    {isValid3 === 'submitted' && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveSpot(youtubeUrl3, 'ramadanyoutubeUrl3') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-304">
                                                <iframe src={youtubeUrl3}
                                                    className="imgba-2" allowFullScreen="" wmode="opaque" id="fitvid219531" style={{ border: "none" }}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Video -4  (YouTube Link)</div>
                                                    <input
                                                        className="divba-11"
                                                        type="text"
                                                        onChange={(e) => validateYouTubeUrl(e, 'youtubeUrl4')}
                                                        value={youtubeUrlt4}
                                                    />
                                                    {isValid4 === 'invalid' && <div className="divba-e">link is not valid</div>}
                                                    {isValid4 === 'submitted' && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveSpot(youtubeUrl4, 'ramadanyoutubeUrl4') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-304">
                                                <iframe src={youtubeUrl4}
                                                    className="imgba-2" allowFullScreen="" wmode="opaque" id="fitvid219531" style={{ border: "none" }}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />
                    <br />
                </div >
                <br />
                <div className='container-for'>
                    <div className='container-for1'>
                        <div className="divba-2">Section Six</div>
                        <div className="divba-303">
                            <div className="divba-4">
                                <div className="divba-5">
                                    <div className="columnba-303">
                                        <div className="divba-6">
                                            <div className="divba-7">
                                                <div className="divba-8">
                                                    <div className="columnba-2">
                                                        <div className="divba-9">
                                                            <div className="divba-42">Upload Image </div>
                                                            <input
                                                                className="divba-11"
                                                                type="file"
                                                                id="mediaUpload"
                                                                name="mediaUpload"
                                                                accept="image/*"
                                                                onChange={(e) => { uploadMedia(e, 'reviews1') }}
                                                            />
                                                            {reviews1 && <div className="divba-p"> data changed successfully</div>}
                                                            <div className="divba-12" onClick={(e) => { saveImage(e, 'reviews1') }}>Save Changes</div>
                                                        </div>
                                                    </div>
                                                    <div className="columnba-3">
                                                        <img
                                                            loading="lazy"
                                                            src={imageReviews1Url}
                                                            className="imgba-2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className='container-for1'>
                        <div className="divba-2">Section Seven</div>
                        <div className="divba-303">
                            <div className="divba-4">
                                <div className="divba-5">
                                    <div className="columnba-303">
                                        <div className="divba-6">
                                            <div className="divba-7">
                                                <div className="divba-8">
                                                    <div className="columnba-2">
                                                        <div className="divba-9">
                                                            <div className="divba-42">Upload Image </div>
                                                            <input
                                                                className="divba-11"
                                                                type="file"
                                                                id="mediaUpload"
                                                                name="mediaUpload"
                                                                accept="image/*"
                                                                onChange={(e) => { uploadMedia(e, 'crazyOffer1') }}
                                                            />
                                                            {crazyOffer1 && <div className="divba-p"> data changed successfully</div>}
                                                            <div className="divba-12" onClick={(e) => { saveImage(e, 'crazyOffer1') }}>Save Changes</div>
                                                        </div>
                                                    </div>
                                                    <div className="columnba-3">
                                                        <img
                                                            loading="lazy"
                                                            src={imageCrazyOffer1Url}
                                                            className="imgba-2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

