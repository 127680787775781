import "./changePassword.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./changePassword.css";
import { jwtDecode } from 'jwt-decode';
const INITIAL_STATE = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: "",
    isSaving: false,
};
;

export default function Modal({ buttonComponent, isOpen }) {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [state, setState] = React.useState(INITIAL_STATE);
    const [email, setEmail] = useState('');


    useEffect(() => {
        const token = localStorage.getItem('token_aos');

        if (token) {
            const decodedToken = jwtDecode(token);
            if (decodedToken?.user?.email) setEmail(decodedToken?.user?.email)
        }
        if (isOpen) {
            setModal(true)
        }
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
    };

    const validatePasswords = () => {
        const { newPassword, confirmPassword } = state;
        if (newPassword !== confirmPassword) {
            setState({ ...state, error: "Passwords must match!" });
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validatePasswords()) return;

        setState({ ...state, isSaving: true });


        try {
            const response = await fetch(`https://www.aos.fit/api/changePassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: state.currentPassword,
                    newPassword: state.newPassword,
                }),
            });
            const data = await response.json();
            if (data.success) {
                setState({ ...state, isSaving: false, error: "Password changed successfully" });
                setTimeout(() => {
                    setModal(!modal)
                }, 2000)
            } else {
                setState({ ...state, isSaving: false, error: data.error });
            }
        } catch (error) {
            setState({ ...state, isSaving: false, error: "An error occurred." });
        }
    };



    const toggleModal = () => {
        if (!modal) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        setModal(!modal);
    };


    // if (modal) {
    //     document.body.classList.add('active-modal')
    // } else {
    //     document.body.classList.remove('active-modal')
    // }

    return (
        <>

            {buttonComponent && buttonComponent(toggleModal)}

            {modal && (
                <div className="modal">
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="modal-contentcp">

                        <div className="divcp-2">Change Password</div>
                        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                            <div className="divcp-3">
                                <div className="divcp-4">Current Password</div>
                                <input
                                    type="password"
                                    name="currentPassword"
                                    minLength={8}
                                    required
                                    onChange={handleChange}
                                    value={state.currentPassword}
                                    className="divcp-5"
                                />
                                <div className="divcp-6">New Password</div>
                                <input
                                    type="password"
                                    name="newPassword"
                                    minLength={8}
                                    required
                                    onChange={handleChange}
                                    value={state.newPassword}
                                    className="divcp-5"
                                />
                                <div className="divcp-6">Confirm Password</div>
                                <input
                                    minLength={8}
                                    type="password"
                                    name="confirmPassword"
                                    required
                                    onChange={handleChange}
                                    value={state.confirmPassword}
                                    className="divcp-5"
                                />
                                {state.error && state.error === "Password changed successfully" && (
                                    <div className="divcp-error2">{state.error}</div>
                                )}
                                {state.error && state.error !== "Password changed successfully" && (
                                    <div className="divcp-error">{state.error}</div>
                                )}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button type="submit" className="divcp-10">
                                    {state.isSaving ? "Saving..." : "Save Changes"}
                                </button>
                            </div>
                        </form>

                    </div>
                </div >
            )
            }
        </>
    );
}

