import React, { useState, useEffect } from "react";
import axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router-dom';
import "./userDetails.css";
import UserDropdown from "../userDropDown/userDropdown"
import StatusDropdown from "../statusDropdown/statusDropdown"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { jwtDecode } from 'jwt-decode';

export default function Modal({ buttonComponent, data, showPop1 }) {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [decodedToken, setDecodedToken] = useState(null);
    const [type, setType] = useState('user');
    const [userType, setUserType] = useState(data?.type);
    const [toDelete, setToDelete] = useState(false);


    useEffect(() => {
        const token = localStorage.getItem('token_aos');

        if (token) {
            const decodedToken = jwtDecode(token);
            setDecodedToken(decodedToken);
            if (decodedToken?.user?.type) setType(decodedToken?.user?.type)
        }
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value;
        console.log('value', value);
        setUserType(value);
    };

    const deleteUserpop = () => {
        setToDelete(true);
    }

    const deleteUser = async () => {
        const response = await fetch(`https://www.aos.fit/api/deleteUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: data._id
            }),
        });

        const result = await response.json();
        console.log(result);
        setModal(!modal);
        showPop1(false)
    }


    const submit = async () => {
        console.log('submit', type, data._id)
        const response = await fetch(`https://www.aos.fit/api/updateUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                type: userType,
                id: data._id
            }),
        });

        const result = await response.json();
        console.log(result);
        setModal(!modal);
        showPop1(false)
    }

    const toggleModal = () => {
        if (!modal) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        console.log(data)
        setModal(!modal);
        setToDelete(false)
        showPop1(false)
    };


    if (modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    return (
        <>
            {buttonComponent && buttonComponent(toggleModal)}

            {modal && (
                <div className="modal">
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="modal-contentud">
                        <div className="divud">
                            <div className="divud-2">
                                <div className="divud-3">
                                    <div className="divud-4">User Code</div>
                                    <div className="divud-5">{data?._id || '9038429443'}</div>
                                </div>
                                <img
                                    loading="lazy"
                                    src="close.svg"
                                    className="imgud"
                                    onClick={toggleModal}
                                />
                            </div>
                            {!toDelete && (<div>
                                <div className="divud-6" />
                                <div className="divud-7">
                                    <div className="divud-8">Basic Information</div>
                                    <div className="divud-9">
                                        <div className="divud-10" onClick={deleteUserpop}>
                                            <img
                                                loading="lazy"
                                                src="bin.svg"
                                                className="img-2"
                                            />
                                            <div className="divud-11">Delete User</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divud-12">
                                    <div className="divud-13">User Name</div>
                                    <div className="divud-16">{`${data?.firstName} ${data?.lastName}`}</div>
                                    <div className="divud-15">Email </div>
                                    <div className="divud-16">{data?.email}</div>
                                    <div className="divud-17">User Type</div>
                                    <select className="divud-18" value={userType} required onChange={(e) => handleInputChange(e)}>
                                        <option value="user">User</option>
                                        <option value="moderator">Moderator</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="divud-20" onClick={submit}>Save Changes</div>
                                </div>

                            </div>)}
                            {toDelete && (
                                <div className="delete-user-confirmation">
                                    <header className="headerdelete" />
                                    <img
                                        loading="lazy"
                                        src="deleteuser.svg"
                                        className="imageDelete"
                                    />
                                    <div className="message">
                                        Are You Sure <br />
                                        <span className="bold-text">You Want to Delete This User</span>
                                    </div>
                                    <div className="actions">
                                        <button className="buttondelete" onClick={toggleModal}>Dismiss</button>
                                        <button className="buttondelete red-button" onClick={deleteUser}>Sure, Delete</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div >
            )
            }
        </>
    );
}

