import React from 'react';
import './contactMessage.css';

function ContactUsMessages(props) {
    const handleExportData = async () => {
        try {
            const response = await fetch(`https://www.aos.fit/api/exportContactToExcel`);

            if (response.ok) {
                console.log('Data exported successfully!');
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'contactData.xlsx';
                a.click();

                URL.revokeObjectURL(url);
            } else {
                console.error('Error exporting data:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred during data export:', error);
        }
    };

    return (
        <div className="main-container">
            <header className="header">Contact Us Messages</header>
            <div className="form-container">
                <button className="input-container">
                    <div className="image-wrapper1" onClick={handleExportData}>
                        <img
                            loading="lazy"
                            src="export.svg"
                            className="img-ContactUsMessages"
                            alt="Contact Us"
                        />
                        <div className="export-data" >
                            Export Data
                        </div>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default ContactUsMessages;
