import React, { useState, useEffect } from "react";
import axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router-dom';
import "./contactUsPopUp.css";
import UserDropdown from "../userDropDown/userDropdown"
import StatusDropdown from "../statusDropdown/statusDropdown"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { jwtDecode } from 'jwt-decode';

export default function Modal({ buttonComponent, data, userType, showPop1 }) {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [notes, setNotes] = useState(undefined);
  const [remainder, setRemainder] = useState(false);
  const [name, setName] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [decodedToken, setDecodedToken] = useState(null);
  const [type, setType] = useState('user');


  useEffect(() => {
    const token = localStorage.getItem('token_aos');

    if (token) {
      const decodedToken = jwtDecode(token);
      setDecodedToken(decodedToken);
      if (decodedToken?.user?.type) setType(decodedToken?.user?.type)
    }

  }, []);
  const handleNotesChange = (event) => {
    const newNotes = event.target.value;
    setNotes(newNotes);
  };

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };
  const handleNameChange = (newName) => {

    setName(newName);
  }
  const setR = () => {
    setRemainder(true)
  }
  const submit = async (option) => {
    const response = await fetch(`https://www.aos.fit/api/updatecontact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        _id: data._id,
        notes,
        assignedTo: name,
        status: selectedStatus,
        remainder: startDate,
        issuedBy: decodedToken.userId
      }),
    });

    const result = await response.json();
    console.log(result);
    setModal(!modal);
    showPop1(false)
  }

  const toggleModal = () => {
    if (!modal) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setModal(!modal);
    showPop1(false)
  };


  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
      {buttonComponent && buttonComponent(toggleModal)}

      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="logoContainer">

              <div className="contactp">
                <div className="contactp-2">
                  <div className="contactp-3">
                    <div className="contactp-4">
                      <div className="contactp-5">Request Code</div>
                      <div className="contactp-6">{data._id}</div>
                    </div>
                    <div className="contactp-7">
                      <div className="contactp-8">Program</div>
                      <div className="contactp-9">{data.program}</div>
                    </div>
                    <img
                      onClick={() => toggleModal()}
                      loading="lazy"
                      src="close.svg"
                      className="img-pop"
                    />
                  </div>
                </div>
                <div className="contactp-10">
                  <div className="contactp-11" />
                </div>
                <div className="contactp-12">
                  <div className="contactp-13">
                    <div className="contactp-14">
                      <div className={`${userType === 'user' ? "contactp-19-custom" : "contactp-19"}`}>
                        <div className="contactp-20" style={{ cursor: "pointer" }} onClick={setR}>
                          <div className="contactp-21" >
                            <img
                              loading="lazy"
                              src="remainder.svg"
                              className="img-pop"
                            />
                            {remainder ? (
                              <DatePicker minDate={moment(new Date()).startOf('day').toDate()} selected={startDate} onChange={(date) => setStartDate(date)} />
                            ) :
                              (
                                <div className="contactp-18" >{data.remainder ? new Date(data.remainder).toLocaleDateString('en-GB', { timeZone: 'UTC' }) : 'Set Reminder'} </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {type === 'moderator' && (<UserDropdown onNameChange={handleNameChange} type={'assign'} item={data} />)}
                      <StatusDropdown onStatusChange={handleStatusChange} type={'assign'} data={data} userType={type} />
                    </div>
                  </div>
                </div>
                <div className="contactp-27">
                  <div className="contactp-28">
                    <div className="contactp-29">Client Name</div>
                    <div className="contactp-30">{`${data.firstName} ${data.lastName} `}</div>
                  </div>
                  <div className="contactp-31">
                    <div className="contactp-29">Email</div>
                    <div className="contactp-30">{data.email}</div>
                  </div>
                </div>
                <div className="contactp-34">
                  <div className="contactp-35">
                    <div className="contactp-29">Phone Number</div>
                    <div className="contactp-30">{data.mobileNumber}</div>
                  </div>
                  <div className="contactp-38">
                    <div className="contactp-29">Gender</div>
                    <div className="contactp-30">{data.gender}</div>
                  </div>
                </div>
                <div className="contactp-41">
                  <div className="contactp-42">
                    <div className="contactp-29">Age</div>
                    <div className="contactp-30">{data.age}</div>
                  </div>
                  <div className="contactp-45">
                    <div className="contactp-29">Location</div>
                    <div className="contactp-30">{data.location}</div>
                  </div>
                </div>
                <div className="contactp-48">
                  <div className="contactp-49">
                    <div className="contactp-29">Request Date</div>
                    <div className="contactp-30">{new Date(data.createdAt).toLocaleString('en-GB', {
                      timeZone: 'Africa/Cairo',
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false, // Use 24-hour format
                    })}</div>
                  </div>
                  <div className="contactp-52">
                    <div className="contactp-29">Last Status Update</div>
                    <div className="contactp-30">{data.updatedAt ?
                      new Date(data.updatedAt).toLocaleString('en-GB', {
                        timeZone: 'Africa/Cairo',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false, // Use 24-hour format
                      }) :
                      new Date(data.createdAt).toLocaleString('en-GB', {
                        timeZone: 'Africa/Cairo',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false, // Use 24-hour format
                      })}
                    </div>
                  </div>
                </div>
                <div className="contactp-55">
                  <div className="contactp-29">Notes</div>
                  <textarea className="contactp-57" onChange={handleNotesChange} placeholder="Please enter your notes here.">
                    {data.notes}
                  </textarea>
                </div>
                <div className="contactp-58">
                  <div className="contactp-59" onClick={submit}>Save Changes</div>
                </div>
              </div>
            </div>
          </div>
        </div >
      )
      }
    </>
  );
}

