import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ReactPaginate from 'react-paginate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './contactUsTable.css'
import ChangePassword from '../changePassword/changePassword'
import UserDropdown from "../userDropDown/userDropdown"
import Popup from '../contactUsPopUp/contactUsPopUp'
import StatusDropdown from "../statusDropdown/statusDropdown"
import FilterDropdown from "../filterDropdown/filterDropdown"
import { jwtDecode } from 'jwt-decode';
import './contactUsTable.css'
const theme = createTheme({
  palette: {
    primary: {
      position: 'relative',
      main: '#00e',
    },
  },
});
const CustomButton = ({ onClick }) => (
  <div className="more-details-container" onClick={onClick}>
    <div className="header-more" >More Details</div>
    <img
      loading="lazy"
      src="arrowright.svg"
      className="img-more"
      alt="Image"
    />
  </div>
);

const getStatusColor = (status) => {
  switch (status) {
    case 'Following Up 1':
      return '#ff6e00';
    case 'Following Up 2':
      return '#ff6e00';
    case 'Following Up 3':
      return '#ff6e00';
    case 'Done':
      return '#1ac6b1';
    case 'Appointment Set':
      return '#0068ff';
    case 'Interested':
      return '#0068ff';
    case 'Subscribed':
      return '#ffc75a';
    case 'Dead-End ':
      return '#f00';
    default:
      return 'black';
  }
};

const TableComponent = ({ token }) => {
  const [data, setData] = useState([]);
  const [decodedToken, setDecodedToken] = useState(null);
  const [type, setType] = useState('user');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(null);
  const [name, setName] = useState(undefined);
  const [filter, setFilter] = useState({});
  const itemsPerPage = 20;
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(filter)
        const { remainderStartDate, remainderEndDate, requestStartDate, requestEndDate, employees, location, status } = filter
        const apiUrl = `https://www.aos.fit/api/getcontact`;
        const requestBody = {
          page: currentPage,
          pageSize: itemsPerPage,
          remainderStartDate,
          remainderEndDate,
          requestStartDate,
          requestEndDate,
          employees,
          location,
          status,
        };

        let response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        const result = await response.json();
        setData(result.data);
        setTotalPages(result.pagination.totalPages)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const token = localStorage.getItem('token_aos');

    if (token) {
      const decodedToken = jwtDecode(token);
      setDecodedToken(decodedToken);
      if (decodedToken?.user?.type) setType(decodedToken?.user?.type)
    }
    fetchData();
  }, []);

  useEffect(() => {


  }, [isPopupOpen1]);





  useEffect(() => {
    const fetchData = async () => {
      try {
        const { remainderStartDate, remainderEndDate, requestStartDate, requestEndDate, employees, location, status } = filter
        const apiUrl = `https://www.aos.fit/api/getcontact`;
        const requestBody = {
          page: currentPage,
          pageSize: itemsPerPage,
          remainderStartDate,
          remainderEndDate,
          requestStartDate,
          requestEndDate,
          employees,
          location,
          status,
        };

        let response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        const result = await response.json();
        setData(result.data);
        setTotalPages(result.pagination.totalPages)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [currentPage]);


  const handleStatusChange = (newStatus, item) => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://www.aos.fit/api/updatecontact`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            _id: item._id,
            status: newStatus,
          }),
        });
        const resp = await response.json()
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    const newData = data.map(obj => {
      if (obj._id === item._id) {
        obj.status = newStatus;
        return obj
      } else {
        return obj
      }
    });
    setData(newData);
    setName(newStatus);
  };


  const handleNameChange = (newName, item) => {

    const fetchData = async () => {
      try {
        const response = await fetch(`https://www.aos.fit/api/updatecontact`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            _id: item._id,
            assignedTo: newName,
          }),
        });
        const resp = await response.json()
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    const newData = data.map(obj => {
      if (obj._id === item._id) {

        obj.assignedTo = newName;
        return obj
      } else {
        return obj
      }
    });
    setData(newData);
    setName(newName);
  }


  const setFilter1 = (filter) => {
    setFilter(filter);
    const fetchData = async () => {
      try {
        console.log('FILKLTEER')
        console.log(filter)
        const { remainderStartDate, remainderEndDate, requestStartDate, requestEndDate, employees, location, status, name, phone } = filter
        const apiUrl = `https://www.aos.fit/api/getcontact`;
        const requestBody = {
          page: 1,
          pageSize: itemsPerPage,
          remainderStartDate,
          remainderEndDate,
          requestStartDate,
          requestEndDate,
          employees,
          location,
          status,
          name,
          phone
        };

        let response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        const result = await response.json();
        setData(result.data);
        setTotalPages(result.pagination.totalPages)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }
  const hidePop1 = () => {
    setIsPopupOpen1(false)

  }
  const showPop1 = () => {
    const fetchData = async () => {
      try {
        console.log('FILKLTEER')
        console.log(filter)
        const { remainderStartDate, remainderEndDate, requestStartDate, requestEndDate, employees, location, status } = filter
        const apiUrl = `https://www.aos.fit/api/getcontact`;
        const requestBody = {
          page: currentPage,
          pageSize: itemsPerPage,
          remainderStartDate,
          remainderEndDate,
          requestStartDate,
          requestEndDate,
          employees,
          location,
          status,
        };

        let response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        const result = await response.json();
        setData(result.data);
        setTotalPages(result.pagination.totalPages)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    setIsPopupOpen1(true)
  }

  const handleChange = (event) => {
    setCurrentPage(event.selected + 1);
    // setCurrentPage(event);
  };

  const handleClosePopUp = () => {
    setIsPopupOpen(false);
  };
  return (
    <div className="loc">
      <div className="inquires">
        <div className="all-inquires"> All inquires</div>
        <FilterDropdown setFilter={setFilter1} filter={filter} />
        {/* <div className="header-table">
          <img
            loading="lazy"
            src="filter.svg"
            className="img-filter"
            alt="Logo"
          />
          <div className="filter">Filter</div>
        </div> */}
      </div>
      <table className="custom-table">
        <thead >
          <tr>
            <th>Request Date</th>
            <th>Program</th>
            <th>Client Name</th>
            {/* <th>Email</th> */}
            <th>Phone Number</th>
            {type === 'moderator' && (
              <th>Assigned To</th>
            )}
            <th>Status</th>
            <th>Reminder</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item._id}>

              <td>{new Date(item.createdAt).toLocaleString('en-GB', {
                timeZone: 'Africa/Cairo',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false, // Use 24-hour format
              })}</td>
              <td>{item.program}</td>
              <td>{`${item.firstName} ${item.lastName}`}</td>
              {/* <td>{item.email}</td> */}
              <td>{item.mobileNumber}</td>
              {type === 'moderator' && (<td>
                <div className='assign-wrapper'>
                  <div className='name-wrapper'>
                    {item?.assignedTo ?
                      `${item?.assignedTo?.firstName} ${item?.assignedTo?.lastName}` :
                      <UserDropdown onNameChange={handleNameChange} item={item} type={'assign1'} />}
                  </div>
                  <div>
                  </div>
                </div>
              </td>)}
              <td style={{ color: getStatusColor(item.status) }}>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <div>
                    {item.status || '-'}
                  </div>
                  <StatusDropdown onStatusChange={handleStatusChange} item={item} type={'assign1'} />
                </div>
              </td>
              <td>{item.remainder ? new Date(item.remainder).toLocaleDateString('en-GB', { timeZone: 'UTC' }) : '_'}</td>
              <td>{item.notes ? item.notes.substring(0, 30) : ' '}</td>
              <td onClick={hidePop1}>
                <Popup showPop1={showPop1} data={item} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
              </td>
            </tr>
          ))}
        </tbody>

      </table >
      <br />
      <div className="bootstrap-pagination-container">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handleChange}
          onClick={handleChange}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          previousLabel="<"
          containerClassName='pagination'
          pageLinkClassName='page-num'
          previousLinkClassName='page-num'
          nextLinkClassName='page-num'
          activeClassName='active'
        />

      </div>
    </div >
  );
};

export default TableComponent;
