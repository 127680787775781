import React, { useState, useEffect, useRef } from 'react';
import './notification.css';
import ChangePassword from '../changePassword/changePassword'
import { jwtDecode } from 'jwt-decode';
const Dropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [decodedToken, setDecodedToken] = useState(null);
    const [type, setType] = useState('user');
    const [data, setData] = useState([]);
    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };


    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token_aos');
        if (token) {
            const decodedToken1 = jwtDecode(token);
            setDecodedToken(decodedToken1);
            console.log(decodedToken1);
            if (decodedToken1?.user?.type) setType(decodedToken1?.user?.type)
        }
        const fetchData = async () => {
            try {
                const decodedToken1 = jwtDecode(token);
                console.log(decodedToken1);
                const apiUrl = `https://www.aos.fit/api/getcontactsofuser?id=${decodedToken1.user?._id}`;
                let response = await fetch(apiUrl);
                const result = await response.json();
                setData(result.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData()
        setInterval(fetchData, 7000)
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, []);
    return (
        <div className="dropdown-container" ref={dropdownRef}>
            {data.length > 0 && (
                <img
                    onClick={handleToggle}
                    loading="lazy"
                    alt="Loading2"
                    src="notification.svg"
                    className="secondary-image"
                />
            )}
            {data.length === 0 && (
                <img
                    onClick={handleToggle}
                    loading="lazy"
                    alt="Loading2"
                    src="bell.svg"
                    className="secondary-image"
                />
            )}
            {isOpen && (
                <div className="scrollable-options">
                    <div className="options-not">
                        {data.map((option) => (
                            <div className='con-not'>
                                <img
                                    loading="lazy"
                                    src="notificationIcon.svg"
                                    className="img-not"
                                />
                                <div className="notification-3">
                                    <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 400 }}>
                                        Kindly reach out to
                                    </span>
                                    <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
                                        &nbsp;{`${option.firstName} ${option.lastName}`}&nbsp;
                                    </span>
                                    <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 400 }}>
                                        today and ensure a follow-up.
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
