import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'header': [1, 2, 3, 4, false] }],
    [{ 'color': ['#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#808080', '#800000', '#008000', '#000080', '#FFA07A', '#20B2AA', '#FFE4E1', '#FFD700', '#FF6347', '#DA70D6', '#4682B4', '#FF4500', '#B0E0E6', '#32CD32', '#FA8072', '#00BFFF', '#7FFF00', '#4B0082', '#FF69B4', '#CD5C5C', '#C0C0C0', '#DB7093', '#00CED1', '#ADFF2F', '#800080', '#9ACD32', '#F08080', '#E6E6FA', '#00FA9A', '#7FFFD4', '#DAA520', '#FF8C00', '#FAEBD7', '#9370DB', '#FFC0CB', '#20B2AA', '#191970', '#DDA0DD', '#8B008B', '#556B2F', '#9932CC', '#8A2BE2', '#8B0000'] }],
    [{ 'align': ['center', 'right', 'justify'] }],

];


const Editor = ({ placeholder, setData }) => {
    const [editorHtml, setEditorHtml] = useState(placeholder);

    const handleChange = (html) => {
        setEditorHtml(html);
        setData(html)
    };

    useEffect(() => {
        setEditorHtml(placeholder);
    }, [placeholder]);

    const modules = {
        toolbar: toolbarOptions,
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color"
    ];

    return (
        <div className="text-editor">
            {/* <CustomToolbar /> */}
            <ReactQuill
                value={editorHtml}
                onChange={handleChange}
                modules={modules}
                formats={formats}
            />
        </div>
    );
};

export default Editor;
