import React, { useState, useEffect, useRef } from 'react';
import './logout.css'; // Create a CSS file for styling
import ChangePassword from '../changePassword/changePassword'
const CustomButton = ({ onClick }) => (
    <div className='con' onClick={onClick}>
        <div className='changePassword'>
            Change Password
        </div>
        <img
            loading="lazy"
            src="lock.svg"
            className="imglogout"
        /> </div>
);
const Dropdown = () => {
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
        setIsOpen(false);
        localStorage.removeItem('token_aos');
        window.location.href = '/';
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleChangePassword = () => {
        setIsChangePasswordOpen(true);
        // setIsOpen(false);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return (
        <div className="dropdown-container" ref={dropdownRef}>

            <img
                onClick={handleToggle}
                loading="lazy"
                alt="Loading3"
                src="arrow.svg"
                className="user-image2"
            />
            {isOpen && (
                <div className="options">
                    <ChangePassword buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
                    <div className='con1' onClick={handleLogout}>
                        <div className='logout'>
                            Logout
                        </div>
                        <img
                            loading="lazy"
                            src="logout.svg"
                            className="imglogout"
                        /></div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
