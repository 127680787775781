import React, { useState, useEffect, useRef } from 'react';
import './statusDropdown.css'; // Create a CSS file for styling

const Dropdown = ({ onStatusChange, type, data, userType, item }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState([]); // State to store fetched options
    const [name, setName] = useState('');
    const dropdownRef = useRef(null);
    const handleToggle = (name) => {
        if (!isOpen) {
            setIsOpen(true);
        }
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (selectedOption) => {
        console.log('Selected Option:', selectedOption);
        setName(selectedOption)
        onStatusChange(selectedOption, item);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <div className={isOpen ? (userType === 'user' ? "dropdown-container-custom" : "dropdown-container") : (userType === 'user' ? "dropdown-container2-custom" : "dropdown-container2")} ref={dropdownRef}>
            {type === 'assign1' && (
                <img src="arrow.svg" alt="Arrow" className="arrow-img1" onClick={handleToggle} />
            )}
            {type === 'assign' && (

                <div className="contactp-23" onClick={handleToggle}>
                    <div className="contactp-24">
                        <div className="contactp-25">
                            <img
                                loading="lazy"
                                src="nike.svg"
                                className="img-pop-4 "
                            />
                            <div className="contactp-26"> {name === '' ? (data.status ? data.status : 'Change Status') : name}</div>
                            < img
                                loading="lazy"
                                src="down.svg"
                                className="img-pop-4 "
                            />
                        </div>
                    </div>
                </div>)}
            {isOpen && (
                <div className="options2">
                    <div className="scrollable-options">
                        <div className="div-option2" onClick={() => handleOptionClick('-')}>&nbsp; &nbsp; -</div>
                        <div className="div-option4" onClick={() => handleOptionClick('Following Up 1')}>&nbsp; &nbsp; Following Up 1</div>
                        <div className="div-option4" onClick={() => handleOptionClick('Following Up 2')}>&nbsp; &nbsp; Following Up 2</div>
                        <div className="div-option4" onClick={() => handleOptionClick('Following Up 3')}>&nbsp; &nbsp; Following Up 3</div>
                        <div className="div-option8" onClick={() => handleOptionClick('Appointment Set')}>&nbsp; &nbsp; Appointment Set </div>
                        <div className="div-option6" onClick={() => handleOptionClick('Done')}>&nbsp; &nbsp; Done</div>
                        <div className="div-option12" onClick={() => handleOptionClick('Dead-End ')}>&nbsp; &nbsp; Dead-End </div>
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default Dropdown;
