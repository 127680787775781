import React, { useState, useEffect, useRef } from 'react';
import './userDropdown.css';

const Dropdown = ({ onNameChange, type, item }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [name, setName] = useState('');
    const dropdownRef = useRef(null);
    const handleToggle = () => {
        if (!isOpen) {
            fetchOptions();
        }
        setIsOpen(!isOpen);
    };


    const handleClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);
    const handleOptionClick = (selectedOption) => {
        setName(`${selectedOption.firstName} ${selectedOption.lastName}`);
        onNameChange(selectedOption, item);
        setIsOpen(false);
    };

    const fetchOptions = async () => {
        try {
            const response = await fetch(`https://www.aos.fit/api/getusers`);
            const data = await response.json();
            setOptions(data.data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    return (
        <div className={isOpen ? "dropdown-container" : "dropdown-container2"} ref={dropdownRef} >
            {type === 'assign1' && (
                <div className='assign'>
                    +Assign
                    <img src="bluearrow.svg" alt="Arrow" className="arrow-img1" onClick={handleToggle} />
                </div>
            )}
            {
                type === 'assign' && (

                    <div className="contactp-19" onClick={handleToggle}>
                        <div className="contactp-20">
                            <div className="contactp-21">
                                <img
                                    loading="lazy"
                                    src="people.svg"
                                    className="img-pop-2 "
                                />
                                <div className="contactp-22">{name === '' ? item?.assignedTo?.firstName ? `${item.assignedTo.firstName} ${item.assignedTo.lastName}` : 'Assign Team' : name}</div>

                                <img
                                    loading="lazy"
                                    src="down1.svg"
                                    className="img-pop-3"
                                />
                            </div>
                        </div>
                    </div>)
            }
            {
                isOpen && (
                    <div className="options1">
                        <div className="scrollable-options">
                            {options.map((option) => (
                                <div className="userwrapper1" key={option.id} onClick={() => handleOptionClick(option)}>
                                    &nbsp;&nbsp;
                                    <div className="user-name1">{`${option.firstName.substring(0, 1)}${option.lastName.substring(0, 1)}` || 'UN'}</div>
                                    <div className="user-name">{`${option.firstName} ${option.lastName}`}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default Dropdown;
