import React, { useState, useEffect } from 'react';
import './climbing.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios'
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function MyComponent(props) {
    const [imageGardenUrl, setImageGardenUrl] = useState('');
    const [gardenName, setGardenName] = useState(null)
    const [garden, setGarden] = useState(false);

    const [imageYoussefUrl, setImageYoussefUrl] = useState('');
    const [youssefName, setYoussefName] = useState(null)
    const [youssef, setYoussef] = useState(false);


    const [imageGymUrl, setImageGymUrl] = useState('');
    const [gymName, setGymName] = useState(null)
    const [gym, setGym] = useState(false);

    const [imageJumpUrl, setImageJumpUrl] = useState('');
    const [jumpName, setJumpName] = useState(null)
    const [jump, setJump] = useState(false);

    const [imageJumpUrl1, setImageJumpUrl1] = useState('');
    const [jumpName1, setJumpName1] = useState(null)
    const [jump1, setJump1] = useState(false);

    const [imageJumpUrl2, setImageJumpUrl2] = useState('');
    const [jumpName2, setJumpName2] = useState(null)
    const [jump2, setJump2] = useState(false);

    const [imageJumpUrl3, setImageJumpUrl3] = useState('');
    const [jumpName3, setJumpName3] = useState(null)
    const [jump3, setJump3] = useState(false);



    const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
    const [assesmentName, setAssesmentName] = useState(null)
    const [assesment, setAssesment] = useState(false);

    const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
    const [allPeopleName, setAllPeopleName] = useState(null)
    const [allPeople, setAllPeople] = useState(false);

    const [imageGym1Url, setImageGym1Url] = useState('');
    const [gym1Name, setGym1Name] = useState(null)
    const [gym1, setGym1] = useState(false);

    const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
    const [crazyOffer1Name, setCrazyOffer1Name] = useState(null)
    const [crazyOffer1, setCrazyOffer1] = useState(false);

    const [imageReviews1Url, setImageReviews1Url] = useState('');
    const [reviews1Name, setReviews1Name] = useState(null)
    const [reviews1, setReviews1] = useState(false);

    const [youtubeUrl1, setYoutubeUrl1] = useState('');
    const [youtubeUrlt1, setYoutubeUrlt1] = useState('');
    const [isValid1, setIsValid1] = useState(true);

    const [youtubeUrl2, setYoutubeUrl2] = useState('');
    const [youtubeUrlt2, setYoutubeUrlt2] = useState('');
    const [isValid2, setIsValid2] = useState(true);

    const [youtubeUrl5, setYoutubeUrl5] = useState('');
    const [youtubeUrlt5, setYoutubeUrlt5] = useState('');
    const [isValid5, setIsValid5] = useState(true);

    const [enteredSpot, setEnteredSpot] = useState(0);
    const [enteredPercentage, setEnteredPercentage] = useState(0);
    const [spot, setSpot] = useState(false);
    const [percentage, setPercentage] = useState(false);
    const [checked, setChecked] = useState(false);


    const [selectedGymDate, setSelectedGymDate] = useState(null);
    const [selectedGymTime, setSelectedGymTime] = useState(null);
    const [gymDay, setGymDay] = useState(0);
    const [gymHours, setGymHours] = useState(0);
    const [gymMinutes, setGymMinutes] = useState(0);
    const [timing, setTiming] = useState(false);
    const [wholeDate, setWholeDate] = useState(null);

    const [selectedGymDate1, setSelectedGymDate1] = useState(null);
    const [selectedGymTime1, setSelectedGymTime1] = useState(null);
    const [gymDay1, setGymDay1] = useState(0);
    const [gymHours1, setGymHours1] = useState(0);
    const [gymMinutes1, setGymMinutes1] = useState(0);
    const [timing1, setTiming1] = useState(false);
    const [wholeDate1, setWholeDate1] = useState(null);

    const [selectedGymDate2, setSelectedGymDate2] = useState(null);
    const [selectedGymTime2, setSelectedGymTime2] = useState(null);
    const [gymDay2, setGymDay2] = useState(0);
    const [gymHours2, setGymHours2] = useState(0);
    const [gymMinutes2, setGymMinutes2] = useState(0);
    const [timing2, setTiming2] = useState(false);
    const [wholeDate2, setWholeDate2] = useState(null);

    const [selectedGymDate3, setSelectedGymDate3] = useState(null);
    const [selectedGymTime3, setSelectedGymTime3] = useState(null);
    const [gymDay3, setGymDay3] = useState(0);
    const [gymHours3, setGymHours3] = useState(0);
    const [gymMinutes3, setGymMinutes3] = useState(0);
    const [timing3, setTiming3] = useState(false);
    const [wholeDate3, setWholeDate3] = useState(null);

    const [selectedGymDate4, setSelectedGymDate4] = useState(null);
    const [selectedGymTime4, setSelectedGymTime4] = useState(null);
    const [gymDay4, setGymDay4] = useState(0);
    const [gymHours4, setGymHours4] = useState(0);
    const [gymMinutes4, setGymMinutes4] = useState(0);
    const [timing4, setTiming4] = useState(false);
    const [wholeDate4, setWholeDate4] = useState(null);

    const [selectedGymDate5, setSelectedGymDate5] = useState(null);
    const [selectedGymTime5, setSelectedGymTime5] = useState(null);
    const [gymDay5, setGymDay5] = useState(0);
    const [gymHours5, setGymHours5] = useState(0);
    const [gymMinutes5, setGymMinutes5] = useState(0);
    const [timing5, setTiming5] = useState(false);
    const [wholeDate5, setWholeDate5] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl1 = `https://www.aos.fit/api/getalldata`;
                let allData = await fetch(apiUrl1);
                const allDataResult = await allData.json();
                setEnteredSpot(allDataResult['conceptSpot'])
                setEnteredPercentage(allDataResult['gymPercentage'])


                setImageJumpUrl1(`https://www.aos.fit/api/public/${allDataResult['climbing1']}`)
                setImageJumpUrl2(`https://www.aos.fit/api/public/${allDataResult['climbing2']}`)
                setImageJumpUrl3(`https://www.aos.fit/api/public/${allDataResult['climbing3']}`)


                setImageCrazyOffer1Url(`https://www.aos.fit/api/public/${allDataResult['conceptcrazyOffer1']}`)
                setImageReviews1Url(`https://www.aos.fit/api/public/${allDataResult['conceptreviews1']}`)
                setYoutubeUrl5(`${allDataResult['climbingyoutubeUrl5']}`)
                setYoutubeUrl2(`${allDataResult['climbingyoutubeUrl2']}`)

                setGymDay(allDataResult['climbingDay'])
                setGymHours(allDataResult['climbingHour'])
                setGymMinutes(allDataResult['climbingMinute'])

                setGymDay1(allDataResult['climbingDay1'])
                setGymHours1(allDataResult['climbingHour1'])
                setGymMinutes1(allDataResult['climbingMinute1'])

                setGymDay2(allDataResult['climbingDay2'])
                setGymHours2(allDataResult['climbingHour2'])
                setGymMinutes2(allDataResult['climbingMinute2'])

                setGymDay3(allDataResult['climbingDay3'])
                setGymHours3(allDataResult['climbingHour3'])
                setGymMinutes3(allDataResult['climbingMinute3'])

                setGymDay4(allDataResult['climbingDay4'])
                setGymHours4(allDataResult['climbingHour4'])
                setGymMinutes4(allDataResult['climbingMinute4'])

                setChecked(!allDataResult['climbingClosed'])
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

    }, []);
    const validateYouTubeUrl = async (event, type) => {
        const url = event.target.value;

        if (type === 'youtubeUrl1') setYoutubeUrlt1(url)
        else if (type === 'youtubeUrl2') setYoutubeUrlt2(url)
        else if (type === 'youtubeUrl5') setYoutubeUrlt5(url)
        if (url !== undefined || url !== '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length === 11) {
                if (type === 'youtubeUrl1') {
                    setIsValid1('ready')
                    setYoutubeUrl1('https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1')
                } else if (type === 'youtubeUrl2') {
                    setYoutubeUrl2('https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1')
                    setIsValid2('ready')
                } else if (type === 'youtubeUrl5') {
                    setYoutubeUrl5('https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1')
                    setIsValid5('ready')
                }
            } else {
                if (type === 'youtubeUrl1') setIsValid1('invalid')
                if (type === 'youtubeUrl2') setIsValid2('invalid')
                if (type === 'youtubeUrl5') setIsValid5('invalid')
            }
        }
    }
    const handleClose = async (event) => {

        if (event.target.checked) {
            const response1 = await fetch(`https://www.aos.fit/api/setData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: false, type: 'climbingClosed' })
            })
            toast('Page is now visible', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: { backgroundColor: 'green', color: 'white' },
                theme: "light",
            });
        } else {
            const response1 = await fetch(`https://www.aos.fit/api/setData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: true, type: 'climbingClosed' })
            })
            toast('Page will be hidden', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: { backgroundColor: 'green', color: 'white' },
                theme: "light",
            });
        }
        setChecked(event.target.checked ? false : true);
    };
    const saveImage = async (event, type) => {
        let fileName = 'garden'
        console.log(type, 'aaaaa')
        const formData = new FormData();
        if (type === 'garden') {
            fileName = 'garden'
            if (gardenName) {
                formData.append('file', gardenName);
                setGarden(true)
            }
        }
        if (type === 'youssef') {
            console.log('entered jump')
            fileName = 'youssef'
            if (jumpName) {
                formData.append('file', jumpName);
                setJump(true)
            }
        }
        if (type === 'climbing1') {
            fileName = 'climbing1'
            if (jumpName1) {
                formData.append('file', jumpName1);
                setJump1(true)
            }
        }

        if (type === 'climbing2') {
            fileName = 'climbing2'
            if (jumpName2) {
                formData.append('file', jumpName2);
                setJump2(true)
            }
        }

        if (type === 'climbing3') {
            fileName = 'climbing3'
            if (jumpName3) {
                formData.append('file', jumpName3);
                setJump3(true)
            }
        }


        if (type === 'allPeople') {
            fileName = 'concept1'
            if (allPeopleName) {
                formData.append('file', allPeopleName);
                setAllPeople(true)
            }
        }
        if (type === 'assesment') {
            fileName = 'concept3'
            if (assesmentName) {
                formData.append('file', assesmentName);
                setAssesment(true)
            }
        }
        if (type === 'crazyOffer1') {
            fileName = 'conceptcrazyOffer1'
            if (crazyOffer1Name) {
                formData.append('file', crazyOffer1Name);
                setCrazyOffer1(true)
            }
        }
        if (type === 'reviews1') {
            fileName = 'conceptreviews1'
            if (reviews1Name) {
                formData.append('file', reviews1Name);
                setReviews1(true)
            }
        }


        const response = await axios.post(`https://www.aos.fit/api/setmedia?filename=${fileName}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        console.log(response.data);
        if (response.ok) {
            console.log('Media uploaded successfully');
        } else {
            // console.error('Media upload failed');
        }
    }
    const handleGymDateChange = (date) => {
        if (date) {
            const currentDate = new Date();
            const differenceInMilliseconds = date - currentDate;
            let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            let hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            const timestamp1 = date.getTime();
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay(days)
            setGymHours(hours)
            setGymMinutes(minutes)
            setSelectedGymDate(date)
            setWholeDate(timestamp1)
        }
    }
    const handleGymDateChange1 = (date) => {
        if (date) {
            const currentDate = new Date();
            const differenceInMilliseconds = date - currentDate;
            let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            let hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            const timestamp1 = date.getTime();
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay1(days)
            setGymHours1(hours)
            setGymMinutes1(minutes)
            setSelectedGymDate1(date)
            setWholeDate1(timestamp1)
        }
    }
    const handleGymDateChange2 = (date) => {
        if (date) {
            const currentDate = new Date();
            const differenceInMilliseconds = date - currentDate;
            let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            let hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            const timestamp1 = date.getTime();
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay2(days)
            setGymHours2(hours)
            setGymMinutes2(minutes)
            setSelectedGymDate2(date)
            setWholeDate2(timestamp1)
        }
    }

    const handleGymDateChange3 = (date) => {
        if (date) {
            const currentDate = new Date();
            const differenceInMilliseconds = date - currentDate;
            let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            let hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            const timestamp1 = date.getTime();
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay3(days)
            setGymHours3(hours)
            setGymMinutes3(minutes)
            setSelectedGymDate3(date)
            setWholeDate3(timestamp1)
        }
    }

    const handleGymDateChange4 = (date) => {
        if (date) {
            const currentDate = new Date();
            const differenceInMilliseconds = date - currentDate;
            let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            let hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            const timestamp1 = date.getTime();
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay4(days)
            setGymHours4(hours)
            setGymMinutes4(minutes)
            setSelectedGymDate4(date)
            setWholeDate4(timestamp1)
        }
    }
    const handleGymTimeChange = (date) => {
        const dateTemp = selectedGymDate || new Date();
        console.log('handleGymTimeChange')
        console.log(date.$H)
        console.log(date.$m)
        console.log(dateTemp)
        console.log(typeof date.$H, typeof date.$m)
        if (typeof date.$H === 'number' && typeof date.$m === 'number') {
            console.log('handleGymTimeChangeInside')
            const currentDate = new Date();
            const timestamp1 = dateTemp.getTime();
            console.log('timestamp1', timestamp1)
            const differenceInMilliseconds = dateTemp - currentDate;
            const additionalHoursMilliseconds = date.$H * 60 * 60 * 1000;
            const subtractedHoursMilliseconds = date.$m * 60 * 1000;
            const addTime = differenceInMilliseconds + additionalHoursMilliseconds + subtractedHoursMilliseconds
            let days = Math.floor(addTime / (1000 * 60 * 60 * 24));
            let hours = Math.floor((addTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((addTime % (1000 * 60 * 60)) / (1000 * 60));
            console.log(days, hours, minutes)
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay(days)
            setGymHours(hours)
            setGymMinutes(minutes)
            setWholeDate(timestamp1 + additionalHoursMilliseconds + subtractedHoursMilliseconds)
        }
    };

    const handleGymTimeChange1 = (date) => {
        const dateTemp = selectedGymDate || new Date();
        console.log('handleGymTimeChange')
        console.log(date.$H)
        console.log(date.$m)
        console.log(dateTemp)
        console.log(typeof date.$H, typeof date.$m)
        if (typeof date.$H === 'number' && typeof date.$m === 'number') {
            console.log('handleGymTimeChangeInside')
            const currentDate = new Date();
            const timestamp1 = dateTemp.getTime();
            console.log('timestamp1', timestamp1)
            const differenceInMilliseconds = dateTemp - currentDate;
            const additionalHoursMilliseconds = date.$H * 60 * 60 * 1000;
            const subtractedHoursMilliseconds = date.$m * 60 * 1000;
            const addTime = differenceInMilliseconds + additionalHoursMilliseconds + subtractedHoursMilliseconds
            let days = Math.floor(addTime / (1000 * 60 * 60 * 24));
            let hours = Math.floor((addTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((addTime % (1000 * 60 * 60)) / (1000 * 60));
            console.log(days, hours, minutes)
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay1(days)
            setGymHours1(hours)
            setGymMinutes1(minutes)
            setWholeDate1(timestamp1 + additionalHoursMilliseconds + subtractedHoursMilliseconds)
        }
    };

    const handleGymTimeChange2 = (date) => {
        const dateTemp = selectedGymDate || new Date();
        console.log('handleGymTimeChange')
        console.log(date.$H)
        console.log(date.$m)
        console.log(dateTemp)
        console.log(typeof date.$H, typeof date.$m)
        if (typeof date.$H === 'number' && typeof date.$m === 'number') {
            console.log('handleGymTimeChangeInside')
            const currentDate = new Date();
            const timestamp1 = dateTemp.getTime();
            console.log('timestamp1', timestamp1)
            const differenceInMilliseconds = dateTemp - currentDate;
            const additionalHoursMilliseconds = date.$H * 60 * 60 * 1000;
            const subtractedHoursMilliseconds = date.$m * 60 * 1000;
            const addTime = differenceInMilliseconds + additionalHoursMilliseconds + subtractedHoursMilliseconds
            let days = Math.floor(addTime / (1000 * 60 * 60 * 24));
            let hours = Math.floor((addTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((addTime % (1000 * 60 * 60)) / (1000 * 60));
            console.log(days, hours, minutes)
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay2(days)
            setGymHours2(hours)
            setGymMinutes2(minutes)
            setWholeDate2(timestamp1 + additionalHoursMilliseconds + subtractedHoursMilliseconds)
        }
    };

    const handleGymTimeChange3 = (date) => {
        const dateTemp = selectedGymDate || new Date();
        console.log('handleGymTimeChange')
        console.log(date.$H)
        console.log(date.$m)
        console.log(dateTemp)
        console.log(typeof date.$H, typeof date.$m)
        if (typeof date.$H === 'number' && typeof date.$m === 'number') {
            console.log('handleGymTimeChangeInside')
            const currentDate = new Date();
            const timestamp1 = dateTemp.getTime();
            console.log('timestamp1', timestamp1)
            const differenceInMilliseconds = dateTemp - currentDate;
            const additionalHoursMilliseconds = date.$H * 60 * 60 * 1000;
            const subtractedHoursMilliseconds = date.$m * 60 * 1000;
            const addTime = differenceInMilliseconds + additionalHoursMilliseconds + subtractedHoursMilliseconds
            let days = Math.floor(addTime / (1000 * 60 * 60 * 24));
            let hours = Math.floor((addTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((addTime % (1000 * 60 * 60)) / (1000 * 60));
            console.log(days, hours, minutes)
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay3(days)
            setGymHours3(hours)
            setGymMinutes3(minutes)
            setWholeDate3(timestamp1 + additionalHoursMilliseconds + subtractedHoursMilliseconds)
        }
    };

    const handleGymTimeChange4 = (date) => {
        const dateTemp = selectedGymDate || new Date();
        console.log('handleGymTimeChange')
        console.log(date.$H)
        console.log(date.$m)
        console.log(dateTemp)
        console.log(typeof date.$H, typeof date.$m)
        if (typeof date.$H === 'number' && typeof date.$m === 'number') {
            console.log('handleGymTimeChangeInside')
            const currentDate = new Date();
            const timestamp1 = dateTemp.getTime();
            console.log('timestamp1', timestamp1)
            const differenceInMilliseconds = dateTemp - currentDate;
            const additionalHoursMilliseconds = date.$H * 60 * 60 * 1000;
            const subtractedHoursMilliseconds = date.$m * 60 * 1000;
            const addTime = differenceInMilliseconds + additionalHoursMilliseconds + subtractedHoursMilliseconds
            let days = Math.floor(addTime / (1000 * 60 * 60 * 24));
            let hours = Math.floor((addTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((addTime % (1000 * 60 * 60)) / (1000 * 60));
            console.log(days, hours, minutes)
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay4(days)
            setGymHours4(hours)
            setGymMinutes4(minutes)
            setWholeDate4(timestamp1 + additionalHoursMilliseconds + subtractedHoursMilliseconds)
        }
    };




    const saveSpot0 = async () => {
        saveSpot(gymDay, 'climbingDay')
        saveSpot(gymHours, 'climbingHour')
        saveSpot(gymMinutes, 'climbingMinute')
        saveSpot(JSON.stringify(wholeDate), 'climbingTimestamp')
        setTiming(true)
    }

    const saveSpot1 = async () => {
        saveSpot(gymDay1, 'climbingDay1')
        saveSpot(gymHours1, 'climbingHour1')
        saveSpot(gymMinutes1, 'climbingMinute1')
        saveSpot(JSON.stringify(wholeDate1), 'climbingTimestamp1')
        setTiming1(true)
    }


    const saveSpot2 = async () => {
        saveSpot(gymDay2, 'climbingDay2')
        saveSpot(gymHours2, 'climbingHour2')
        saveSpot(gymMinutes2, 'climbingMinute2')
        saveSpot(JSON.stringify(wholeDate2), 'climbingTimestamp2')
        setTiming2(true)
    }

    const saveSpot3 = async () => {
        saveSpot(gymDay3, 'climbingDay3')
        saveSpot(gymHours3, 'climbingHour3')
        saveSpot(gymMinutes3, 'climbingMinute3')
        saveSpot(JSON.stringify(wholeDate3), 'climbingTimestamp3')
        setTiming3(true)
    }



    const saveSpot4 = async () => {
        saveSpot(gymDay4, 'climbingDay4')
        saveSpot(gymHours4, 'climbingHour4')
        saveSpot(gymMinutes4, 'climbingMinute4')
        saveSpot(JSON.stringify(wholeDate4), 'climbingTimestamp4')
        setTiming(true)
    }

    const saveSpot = async (data, type) => {

        if (type === 'gymPercentage') setPercentage(true)
        if (type === 'conceptSpot') setSpot(true)
        if (type === 'conceptyoutubeUrl1') {
            if (isValid1 == 'ready') {
                setIsValid1('submitted')
            } else {
                return setIsValid1('invalid')
            }
        }
        if (type === 'conceptyoutubeUrl2') {
            if (isValid2 == 'ready') {
                setIsValid2('submitted')
            } else {
                return setIsValid2('invalid')
            }
        }
        if (type === 'climbingyoutubeUrl2') {
            if (isValid2 == 'ready') {
                setIsValid2('submitted')
            } else {
                return setIsValid2('invalid')
            }
        }
        if (type === 'climbingyoutubeUrl5') {
            if (isValid5 == 'ready') {
                setIsValid5('submitted')
            } else {
                return setIsValid5('invalid')
            }
        }
        const response1 = await fetch(`https://www.aos.fit/api/setData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: data, type: type })
        })
        console.log({ data: data, type: type }, response1)

    }

    const uploadMedia = async (event, type) => {
        try {
            const file = event.target.files[0];

            console.log(event, type)
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {

                    if (type === 'garden') {
                        setGardenName(file)
                        setImageGardenUrl(reader.result);
                    }
                    if (type === 'youssef') {
                        setYoussefName(file)
                        setImageYoussefUrl(reader.result)
                    }
                    if (type === 'gym') {
                        setImageGymUrl(reader.result)
                        setGymName(file)
                    }
                    if (type === 'gym1') {
                        setImageGym1Url(reader.result)
                        setGym1Name(file)
                    }
                    if (type === 'jump') {
                        console.log('IN 1')
                        setImageJumpUrl(reader.result)
                        setJumpName(file)
                    }
                    if (type === 'jump1') {
                        setImageJumpUrl1(reader.result)
                        setJumpName1(file)
                    }
                    if (type === 'jump2') {
                        setImageJumpUrl2(reader.result)
                        setJumpName2(file)
                    }
                    if (type === 'jump3') {
                        setImageJumpUrl3(reader.result)
                        setJumpName3(file)
                    }

                    if (type === 'assesment') {
                        setImageAssesmentUrl(reader.result)
                        setAssesmentName(file)
                    }
                    if (type === 'allPeople') {
                        setImageAllPeopleUrl(reader.result)
                        setAllPeopleName(file)
                    }
                    if (type === 'crazyOffer1') {
                        setImageCrazyOffer1Url(reader.result)
                        setCrazyOffer1Name(file)
                    }
                    if (type === 'reviews1') {
                        setImageReviews1Url(reader.result)
                        setReviews1Name(file)
                    }
                };
                reader.readAsDataURL(file);
            }
        } catch (error) {
            console.error('Error uploading media:', error);
            // Handle error if needed
        }
    };

    return (
        <>
            <div className="divba">
                <div className="divba-202"> <span>
                    Climbing Program Content Editor
                </span>
                    <Switch checked={checked} onChange={handleClose} />
                </div>
                <br />
                <div className="divba-2">Section One</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <div className="divba-505">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Video -1  (YouTube Link)</div>
                                                    <input
                                                        className="divba-11"
                                                        type="text"
                                                        onChange={(e) => validateYouTubeUrl(e, 'youtubeUrl5')}
                                                        value={youtubeUrlt5}
                                                    />
                                                    {isValid5 === 'invalid' && <div className="divba-e">link is not valid</div>}
                                                    {isValid5 === 'submitted' && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveSpot(youtubeUrl5, 'climbingyoutubeUrl5') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-304">
                                                <iframe src={youtubeUrl5}
                                                    className="imgba-2" allowFullScreen="" wmode="opaque" id="fitvid219531" style={{ border: "none" }}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba-4">
                                <div className="divba-13">
                                    <div className="divba-14">
                                        <div className="columnba-5">
                                            <div className="divba-15">
                                                <div className="divba-16">Section One Countdown</div>
                                                <div className="divba-17">
                                                    <div className="divba-18">
                                                        <div className="divba-19">Set Date</div>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Basic date picker"
                                                                value={selectedGymDate}
                                                                onChange={handleGymDateChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>

                                                    <div className="divba-22">
                                                        <div className="divba-23">Set Time</div>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                value={selectedGymTime}
                                                                onChange={handleGymTimeChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                label="Basic time picker" />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                                {timing && <div className="divba-p"> data changed successfully</div>}
                                                <div className="divba-27" onClick={saveSpot0}>Save Changes</div>
                                            </div>
                                        </div>
                                        <div className="columnba-6">
                                            <div className="divba-28">
                                                <div className="divba-29">
                                                    <div className="divba-30">
                                                        <div className="divba-31">{gymDay}</div>
                                                        <div className="divba-32">Day</div>
                                                    </div>
                                                    <div className="divba-33">
                                                        <div className="divba-34">{gymHours}</div>
                                                        <div className="divba-35">Hour</div>
                                                    </div>
                                                    <div className="divba-36">
                                                        <div className="divba-37">{gymMinutes}</div>
                                                        <div className="divba-38">Minute</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />

                </div>
                <br />
                <div className="divba-2">Section Two</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <div className="divba-5">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Upload Image 1</div>
                                                    <input
                                                        className="divba-11"
                                                        type="file"
                                                        id="mediaUpload"
                                                        name="mediaUpload"
                                                        accept="image/*"
                                                        onChange={(e) => { uploadMedia(e, 'jump1') }}
                                                    />
                                                    {jump1 && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveImage(e, 'climbing1') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <img
                                                    loading="lazy"
                                                    src={imageJumpUrl1}
                                                    className="imgba-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Upload Image 2</div>
                                                    <input
                                                        className="divba-11"
                                                        type="file"
                                                        id="mediaUpload"
                                                        name="mediaUpload"
                                                        accept="image/*"
                                                        onChange={(e) => { uploadMedia(e, 'jump2') }}
                                                    />
                                                    {jump2 && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveImage(e, 'climbing2') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <img
                                                    loading="lazy"
                                                    src={imageJumpUrl2}
                                                    className="imgba-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="divba-4">
                        <div className="divba-5">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Upload Image 3</div>
                                                    <input
                                                        className="divba-11"
                                                        type="file"
                                                        id="mediaUpload"
                                                        name="mediaUpload"
                                                        accept="image/*"
                                                        onChange={(e) => { uploadMedia(e, 'jump3') }}
                                                    />
                                                    {jump3 && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveImage(e, 'climbing3') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <img
                                                    loading="lazy"
                                                    src={imageJumpUrl3}
                                                    className="imgba-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="columnba">
                                <div className="divba-13">
                                    <div className="divba-14">
                                        <div className="columnba-5">
                                            <div className="divba-15">
                                                <div className="divba-16">Section Two Countdown</div>
                                                <div className="divba-17">
                                                    <div className="divba-18">
                                                        <div className="divba-19">Set Date</div>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Basic date picker"
                                                                value={selectedGymDate1}
                                                                onChange={handleGymDateChange1}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>

                                                    <div className="divba-22">
                                                        <div className="divba-23">Set Time</div>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                value={selectedGymTime1}
                                                                onChange={handleGymTimeChange1}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                label="Basic time picker" />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                                {timing1 && <div className="divba-p"> data changed successfully</div>}
                                                <div className="divba-27" onClick={saveSpot1}>Save Changes</div>
                                            </div>
                                        </div>
                                        <div className="columnba-6">
                                            <div className="divba-28">
                                                <div className="divba-29">
                                                    <div className="divba-30">
                                                        <div className="divba-31">{gymDay1}</div>
                                                        <div className="divba-32">Day</div>
                                                    </div>
                                                    <div className="divba-33">
                                                        <div className="divba-34">{gymHours1}</div>
                                                        <div className="divba-35">Hour</div>
                                                    </div>
                                                    <div className="divba-36">
                                                        <div className="divba-37">{gymMinutes1}</div>
                                                        <div className="divba-38">Minute</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    <br />
                    <br />
                </div>
                <br />

                <div className="divba-2">Section Three</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <div className="divba-505">
                            <div className="columnba-4">
                                <div className="divba-13">
                                    <div className="divba-14">
                                        <div className="columnba-5">
                                            <div className="divba-15">
                                                <div className="divba-16">Section Three Countdown</div>
                                                <div className="divba-17">
                                                    <div className="divba-18">
                                                        <div className="divba-19">Set Date</div>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Basic date picker"
                                                                value={selectedGymDate2}
                                                                onChange={handleGymDateChange2}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>

                                                    <div className="divba-22">
                                                        <div className="divba-23">Set Time</div>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                value={selectedGymTime2}
                                                                onChange={handleGymTimeChange2}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                label="Basic time picker" />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                                {timing2 && <div className="divba-p"> data changed successfully</div>}
                                                <div className="divba-27" onClick={saveSpot2}>Save Changes</div>
                                            </div>
                                        </div>
                                        <div className="columnba-6">
                                            <div className="divba-28">
                                                <div className="divba-29">
                                                    <div className="divba-30">
                                                        <div className="divba-31">{gymDay2}</div>
                                                        <div className="divba-32">Day</div>
                                                    </div>
                                                    <div className="divba-33">
                                                        <div className="divba-34">{gymHours2}</div>
                                                        <div className="divba-35">Hour</div>
                                                    </div>
                                                    <div className="divba-36">
                                                        <div className="divba-37">{gymMinutes2}</div>
                                                        <div className="divba-38">Minute</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <br />
                    <br />

                </div>
                <br />

                <div className="divba-2">Section Four</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <div className="divba-505">
                            <div className="columnba">
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Video -1  (YouTube Link)</div>
                                                    <input
                                                        className="divba-11"
                                                        type="text"
                                                        onChange={(e) => validateYouTubeUrl(e, 'youtubeUrl2')}
                                                        value={youtubeUrlt2}
                                                    />
                                                    {isValid2 === 'invalid' && <div className="divba-e">link is not valid</div>}
                                                    {isValid2 === 'submitted' && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-12" onClick={(e) => { saveSpot(youtubeUrl2, 'climbingyoutubeUrl2') }}>Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-304">
                                                <iframe src={youtubeUrl2}
                                                    className="imgba-2" allowFullScreen="" wmode="opaque" id="fitvid219531" style={{ border: "none" }}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba-4">
                                <div className="divba-13">
                                    <div className="divba-14">
                                        <div className="columnba-5">
                                            <div className="divba-15">
                                                <div className="divba-16">Section Four Countdown</div>
                                                <div className="divba-17">
                                                    <div className="divba-18">
                                                        <div className="divba-19">Set Date</div>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Basic date picker"
                                                                value={selectedGymDate3}
                                                                onChange={handleGymDateChange3}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>

                                                    <div className="divba-22">
                                                        <div className="divba-23">Set Time</div>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                value={selectedGymTime3}
                                                                onChange={handleGymTimeChange3}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                label="Basic time picker" />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                                {timing3 && <div className="divba-p"> data changed successfully</div>}
                                                <div className="divba-27" onClick={saveSpot3}>Save Changes</div>
                                            </div>
                                        </div>
                                        <div className="columnba-6">
                                            <div className="divba-28">
                                                <div className="divba-29">
                                                    <div className="divba-30">
                                                        <div className="divba-31">{gymDay3}</div>
                                                        <div className="divba-32">Day</div>
                                                    </div>
                                                    <div className="divba-33">
                                                        <div className="divba-34">{gymHours3}</div>
                                                        <div className="divba-35">Hour</div>
                                                    </div>
                                                    <div className="divba-36">
                                                        <div className="divba-37">{gymMinutes3}</div>
                                                        <div className="divba-38">Minute</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />

                </div>

                <br />



            </div >
        </>
    );
}

