import './login.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const INITIAL_STATE = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: "",
    isSaving: false,
};
;
export default function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [backendError, setBackendError] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [forgetPasswordClicked, setForgetPasswordClicked] = useState(false);
    const [mailSent, setMailSent] = useState(false);
    const [mailSentError, setMailSentError] = useState(false);
    const navigate = useNavigate();
    const [state, setState] = React.useState(INITIAL_STATE);


    useEffect(() => {
        setEmailError(validateEmail(email) ? '' : 'Invalid email format');
        setIsButtonDisabled(emailError || passwordError);
    }, [email]);

    useEffect(() => {
        console.log(state)
    }, [state]);

    useEffect(() => {
        setPasswordError(validatePassword(password) ? '' : 'Password must be at least 8 characters');
        setIsButtonDisabled(emailError || passwordError);
    }, [password]);

    useEffect(() => {
        if (!(email.length === 0 || password.length === 0)) {
            setIsButtonDisabled(emailError || passwordError);
        }
    }, [emailError, passwordError]);

    const validateEmail = (input) => {
        if (input === '') return true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(input);
    };

    const validatePassword = (input) => {
        if (input === '') return true;
        return input.length >= 8;
    };

    const validatePasswords = () => {
        const { newPassword, confirmPassword } = state;
        if (newPassword !== confirmPassword) {
            setState({ ...state, error: "Passwords must match!" });
            return false;
        }
        return true;
    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validatePasswords()) return;

        setState({ ...state, isSaving: true });


        try {
            const response = await fetch(`https://www.aos.fit/api/changePassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: state.currentPassword,
                    newPassword: state.newPassword,
                }),
            });
            const data = await response.json();
            if (data.success) {
                setState({ ...state, isSaving: false, error: "Password changed successfully" });
            } else {
                setState({ ...state, isSaving: false, error: data.error });
            }
        } catch (error) {
            setState({ ...state, isSaving: false, error: "An error occurred." });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
    };


    const handleSendPassword = async () => {
        const response = await fetch(`https://www.aos.fit/api/forgetPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });
        console.log(response)
        const data = await response.json();
        console.log(data)
        if (response.ok) {
            console.log('Login successful', data);
            setMailSent(true);
        } else {
            setMailSentError(true)
        }
    }

    const handleEmailInput = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordInput = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = async () => {
        try {
            if (!validateEmail(email) || !validatePassword(password)) {
                setBackendError('Please fix the validation errors before submitting.');
                return;
            }
            if (email.length == 0) {
                setBackendError('Please enter Your email');
                return
            }

            if (password.length == 0) {
                setBackendError('Please enter Your password');
                return
            }

            const response = await fetch(`https://www.aos.fit/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Login successful', data);

                localStorage.setItem('token_aos', data?.data?.token);
                navigate('/contact', { token: data?.data?.token });
                setBackendError('');
            } else {
                const errorData = await response.json();
                setBackendError(errorData.error || 'Login failed');
            }
        } catch (error) {
            console.error('Error:', error);
            setBackendError('An unexpected error occurred');
        }
    };

    const handleForgetPasswordClick = () => {
        setForgetPasswordClicked(true);
        // You can add additional logic here if needed
    };
    const handleLoginClick = () => {
        setForgetPasswordClicked(false);
        // You can add additional logic here if needed
    };

    if (forgetPasswordClicked) {
        return (
            <>
                <div className="column">
                    <div className="div-login-3">
                        <div className="div-login-4">
                            <div style={{ color: "#0000EE" }}>
                                Welcome To <br />
                            </div>
                            <div style={{ color: "rgba(46,53,58,1)" }}>
                                AOS Management Dashboard
                            </div>
                        </div>
                        <div className="div-login-5">
                            <img
                                loading="lazy"
                                src="backimg.svg"
                                className="backImage"
                                onClick={handleLoginClick}
                            />
                            <div className="forgetpass">Forget Password</div>
                            <div className="div-login-7">Email</div>
                            <input
                                className={`div-login-8 ${emailError ? 'error' : ''}`}
                                type="email"
                                name="email"
                                required
                                placeholder="example:name@gmail.com"
                                value={email}
                                onInput={handleEmailInput}
                                style={{ color: emailError ? 'black' : '' }}
                            />
                            <div className="div-login-12" style={{ color: 'red' }}>
                                {emailError}
                            </div>
                            <div className="resetpassword" onClick={handleSendPassword}>Reset Password</div>

                            {mailSent && <div style={{ color: 'green', marginTop: "10px" }}> Mail sent please check your mail</div>}
                            {mailSentError && <div style={{ color: 'red', marginTop: "10px" }}> Error sending Mail </div>}
                            <div className="resendmail" onClick={handleSendPassword}>Resend Emails </div>
                            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                                <div className="div-9">Enter Sent Password</div>
                                <input
                                    className="div-login-8"
                                    type="password"
                                    name="currentPassword"
                                    required
                                    minLength={8}
                                    placeholder="*************"
                                    onChange={handleChange}
                                    value={state.currentPassword}
                                    style={{ color: passwordError ? 'black' : '' }}
                                />
                                <div className="div-login-7">New Password</div>
                                <input
                                    className={`div-login-8`}
                                    type="password"
                                    minLength={8}
                                    name="newPassword"
                                    required
                                    onChange={handleChange}
                                    placeholder="*************"
                                    value={state.newPassword}
                                    style={{ color: passwordError ? 'black' : '' }}
                                />
                                <div className="div-login-7">Confirm Password</div>
                                <input
                                    className={`div-login-8`}
                                    type="password"
                                    minLength={8}
                                    onChange={handleChange}
                                    name="confirmPassword"
                                    required
                                    placeholder="*************"
                                    value={state.confirmPassword}
                                    style={{ color: passwordError ? 'black' : '' }}
                                />
                                {state.error && state.error === "Password changed successfully" && (
                                    <div className="divcp-error2">{state.error}</div>
                                )}
                                {state.error && state.error !== "Password changed successfully" && (
                                    <div className="divcp-error">{state.error}</div>
                                )}
                                <button type="submit" className='div-login-button' >
                                    Login
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="column">
                <div className="div-login-3">
                    <div className="div-login-4">
                        <div style={{ color: "#0000EE" }}>
                            Welcome To <br />
                        </div>
                        <div style={{ color: "rgba(46,53,58,1)" }}>
                            AOS Management Dashboard
                        </div>
                    </div>
                    <div className="div-login-5">
                        <div className="div-login-6">Login</div>
                        <div className="div-login-7">Email</div>
                        <input
                            className={`div-login-8 ${emailError ? 'error' : ''}`}
                            type="email"
                            name="email"
                            required
                            placeholder="example:name@gmail.com"
                            value={email}
                            onInput={handleEmailInput}
                            style={{ color: emailError ? 'black' : '' }}
                        />
                        <div className="div-login-12" style={{ color: 'red' }}>
                            {emailError}
                        </div>
                        <div className="div-login-9">Password</div>
                        <input
                            className={`div-login-8 ${passwordError ? 'error' : ''}`}
                            type="password"
                            name="password"
                            required
                            placeholder="*************"
                            value={password}
                            onInput={handlePasswordInput}
                            style={{ color: passwordError ? 'black' : '' }}
                        />
                        <div className="div-login-12" style={{ color: 'red' }}>
                            {passwordError}
                        </div>
                        <div className={`${isButtonDisabled ? 'disabled1' : 'div-login-11'}  `} onClick={isButtonDisabled ? null : handleLogin}>
                            Login
                        </div>

                        <div className="div-login-12" style={{ color: 'red' }}>
                            {backendError}
                        </div>
                        <div className="ForgetPassword" onClick={handleForgetPasswordClick}>Forget Your Password ?</div>
                        <img
                            loading="lazy"
                            srcSet="aos.png"
                            className="img"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
