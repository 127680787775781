import React, { useState, useEffect, useRef } from 'react';
import './revival.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Switch from '@mui/material/Switch';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Editor from './editor';

export default function MyComponent(props) {

    const editorRef = useRef(null);
    const [enteredSpot, setEnteredSpot] = useState(0);
    const [spot, setSpot] = useState(false);
    const [percentage, setPercentage] = useState(false);
    const [selectedGymDate, setSelectedGymDate] = useState(null);
    const [selectedGymTime, setSelectedGymTime] = useState(null);
    const [gymDay, setGymDay] = useState(0);
    const [gymHours, setGymHours] = useState(0);
    const [gymMinutes, setGymMinutes] = useState(0);
    const [timing, setTiming] = useState(false);
    const [wholeDate, setWholeDate] = useState(null);
    const [checked, setChecked] = useState(false);
    const [revivalData, setRevivalData] = useState(null);
    const [revivalTitle, setRevivalTitle] = useState(null);
    const [revivalProgramName, setRevivalProgramName] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {

                const apiUrl1 = `https://www.aos.fit/api/getalldata`;
                let allData = await fetch(apiUrl1);
                const allDataResult = await allData.json();
                setEnteredSpot(allDataResult['revivalSpot'])
                setGymDay(allDataResult['revivalDay'])
                setGymHours(allDataResult['revivalHour'])
                setGymMinutes(allDataResult['revivalMinute'])
                setChecked(!allDataResult['revivalClosed'])
                setRevivalData(allDataResult['revivalData'])
                setRevivalTitle(allDataResult['revivalTitle'])
                setRevivalProgramName(allDataResult['revivalProgramName'])
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

    }, []);


    const handleClose = async (event) => {

        if (event.target.checked) {
            const response1 = await fetch(`https://www.aos.fit/api/setData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: false, type: 'revivalClosed' })
            })
            toast('Page is now visible', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: { backgroundColor: 'green', color: 'white' },
                theme: "light",
            });
        } else {
            const response1 = await fetch(`https://www.aos.fit/api/setData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: true, type: 'revivalClosed' })
            })
            toast('Page will be hidden', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: { backgroundColor: 'green', color: 'white' },
                theme: "light",
            });
        }
        setChecked(event.target.checked ? false : true);
    };

    const handleGymDateChange = (date) => {
        if (date) {
            const currentDate = new Date();
            const differenceInMilliseconds = date - currentDate;
            let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            let hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            const timestamp1 = date.getTime();
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay(days)
            setGymHours(hours)
            setGymMinutes(minutes)
            setSelectedGymDate(date)
            setWholeDate(timestamp1)
        }
    }
    const handleGymTimeChange = (date) => {
        const dateTemp = selectedGymDate || new Date();
        console.log('handleGymTimeChange')
        console.log(date.$H)
        console.log(date.$m)
        console.log(dateTemp)
        console.log(typeof date.$H, typeof date.$m)
        if (typeof date.$H === 'number' && typeof date.$m === 'number') {
            console.log('handleGymTimeChangeInside')
            const currentDate = new Date();
            const timestamp1 = dateTemp.getTime();
            console.log('timestamp1', timestamp1)
            const differenceInMilliseconds = dateTemp - currentDate;
            const additionalHoursMilliseconds = date.$H * 60 * 60 * 1000;
            const subtractedHoursMilliseconds = date.$m * 60 * 1000;
            const addTime = differenceInMilliseconds + additionalHoursMilliseconds + subtractedHoursMilliseconds
            let days = Math.floor(addTime / (1000 * 60 * 60 * 24));
            let hours = Math.floor((addTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((addTime % (1000 * 60 * 60)) / (1000 * 60));
            console.log(days, hours, minutes)
            days = Math.max(days, 0);
            hours = Math.max(hours, 0);
            minutes = Math.max(minutes, 0);
            setGymDay(days)
            setGymHours(hours)
            setGymMinutes(minutes)
            setWholeDate(timestamp1 + additionalHoursMilliseconds + subtractedHoursMilliseconds)
        }
    };

    const handleSpotChange = (event) => {
        let value = event.target.value;
        if (value > 10000) {
            value = 10000
        }
        setEnteredSpot(value);
    };

    const saveSpot1 = async () => {
        saveSpot(gymDay, 'revivalDay')
        saveSpot(gymHours, 'revivalHour')
        saveSpot(gymMinutes, 'revivalMinute')
        saveSpot(JSON.stringify(wholeDate), 'revivalTimestamp')
        setTiming(true)
    }

    const saveSpot = async (data, type) => {

        if (type === 'gymPercentage') setPercentage(true)
        if (type === 'revivalSpot') setSpot(true)

        const response1 = await fetch(`https://www.aos.fit/api/setData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: data, type: type })
        })
    }

    const saveTitle = async (type) => {

        const response1 = await fetch(`https://www.aos.fit/api/setData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: revivalTitle, type: 'revivalTitle' })
        })
        toast('Revival title saved sucessfully', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { backgroundColor: 'green', color: 'white' },
            theme: "light",
        });
    }

    const saveProgramName = async (type) => {

        const response1 = await fetch(`https://www.aos.fit/api/setData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: revivalProgramName, type: 'revivalProgramName' })
        })
        toast('Revival program name saved sucessfully', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { backgroundColor: 'green', color: 'white' },
            theme: "light",
        });
    }

    const saveData = async (type) => {

        const response1 = await fetch(`https://www.aos.fit/api/setData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: revivalData, type: 'revivalData' })
        })
        toast('Revival paragraph saved sucessfully', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { backgroundColor: 'green', color: 'white' },
            theme: "light",
        });
    }

    const handleTitle = (data) => {
        setRevivalTitle(data)
    }

    const handleBody = (data) => {
        setRevivalData(data)
    }

    const handleProgramName = (data) => {
        setRevivalProgramName(data)
    }


    return (
        <>
            <div className="divba">
                <div className="divba-202"> <span>
                    Revival Program Content Editor
                </span>
                    <Switch checked={checked} onChange={handleClose} />
                </div>
                <br />
                <div className="divba-2">Section One</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <div className="divba-5">
                            <div className='columnba'>
                                <div className="divba-6">
                                    <div className="divba-7">
                                        <div className="divba-8">
                                            <div className="columnba-2">
                                                <div className="divba-9">
                                                    <div className="divba-42">Section One Available Spots </div>
                                                    <div className="divba-43"># of Spots Available</div>
                                                    <input className="divba-11" type="number" min={0} max={9999} onChange={handleSpotChange} default={0} value={enteredSpot} />
                                                    {spot && <div className="divba-p"> data changed successfully</div>}
                                                    <div className="divba-45" value="spots" onClick={() => { saveSpot(enteredSpot, 'revivalSpot') }} >Save Changes</div>
                                                </div>
                                            </div>
                                            <div className="columnba-3">
                                                <div className="divba-46">
                                                    <div className="divba-47">ONLY</div>
                                                    <div className="divba-48">{enteredSpot} SPOTS &nbsp;</div>
                                                    <div className="divba-49">AVAILABLE</div>
                                                    <div className="divba-50"> !&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columnba-4">
                                <div className="divba-13">
                                    <div className="divba-14">
                                        <div className="columnba-5">
                                            <div className="divba-15">
                                                <div className="divba-16">Section One Countdown</div>
                                                <div className="divba-17">
                                                    <div className="divba-18">
                                                        <div className="divba-19">Set Date</div>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                label="Basic date picker"
                                                                value={selectedGymDate}
                                                                onChange={handleGymDateChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>

                                                    <div className="divba-22">
                                                        <div className="divba-23">Set Time</div>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                value={selectedGymTime}
                                                                onChange={handleGymTimeChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                label="Basic time picker" />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                                {timing && <div className="divba-p"> data changed successfully</div>}
                                                <div className="divba-27" onClick={saveSpot1}>Save Changes</div>
                                            </div>
                                        </div>
                                        <div className="columnba-6">
                                            <div className="divba-28">
                                                <div className="divba-29">
                                                    <div className="divba-30">
                                                        <div className="divba-31">{gymDay}</div>
                                                        <div className="divba-32">Day</div>
                                                    </div>
                                                    <div className="divba-33">
                                                        <div className="divba-34">{gymHours}</div>
                                                        <div className="divba-35">Hour</div>
                                                    </div>
                                                    <div className="divba-36">
                                                        <div className="divba-37">{gymMinutes}</div>
                                                        <div className="divba-38">Minute</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
                <br />
                <div className="divba-2">Title</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <Editor placeholder={revivalTitle} setData={handleTitle} />
                    </div>
                    <div className="divba-27" onClick={saveTitle}>Save Changes</div>
                </div>
                <br />
                <div className="divba-2">Program name</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <Editor placeholder={revivalProgramName} setData={handleProgramName} />
                    </div>
                    <div className="divba-27" onClick={saveProgramName}>Save Changes</div>
                </div>
                <br />

                <div className="divba-2">Revival body</div>
                <div className="divba-3">
                    <div className="divba-4">
                        <Editor placeholder={revivalData} setData={handleBody} />
                    </div>
                    <div className="divba-27" onClick={saveData}>Save Changes</div>
                </div>
                <br />
            </div >
        </>
    );
}

