import './images.css'
export default function Images() {
    return (
        <div className="column-2">
            <div className="div-login-13">
                <div className="div-login-15">
                    <div className="column-3">
                        <img
                            loading="lazy"
                            src="./youssef1.png"
                            className="img-login-3"
                        />
                    </div>
                    <div className="column-3">
                        <img
                            loading="lazy"
                            src="./girl1.png"
                            className="img-login-3"
                        />
                    </div>
                </div>
                <div className="div-login-15">
                    <div className="column-3">
                        <img
                            loading="lazy"
                            src="./gym1.png"
                            className="img-login-3"
                        />
                    </div>
                    <div className="column-3">
                        <img
                            loading="lazy"
                            src='./jump1.png'
                            className="img-login-3"
                        />
                    </div>
                </div>
                <div className="div-login-15">
                    <div className="column-3">
                        <img
                            loading="lazy"
                            srcSet="./smile1.png"
                            className="img-login-3"
                        />
                    </div>
                    <div className="column-3">
                        <img
                            loading="lazy"
                            srcSet="./youssef2.png"
                            className="img-login-3"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}