import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserDropdown from "../userDropDown/userDropdown"
import Popup from '../userDetails/userDetails'
import StatusDropdown from "../statusDropdown/statusDropdown"
import FilterDropdown from "../filterDropdown/filterDropdown"
import { jwtDecode } from 'jwt-decode';
import './userTable.css'
const theme = createTheme({
    palette: {
        primary: {
            position: 'relative',
            main: '#00e',
        },
    },
});
const CustomButton = ({ onClick }) => (
    <div className="more-details-container" onClick={onClick}>
        <div className="header-more" >More Details</div>
        <img
            loading="lazy"
            src="arrowright.svg"
            className="img-more"
            alt="Image"
        />
    </div>
);

const getStatusColor = (status) => {
    switch (status) {
        case 'user':
            return '#ff6e00';
        case 'moderator':
            return '#0068ff';
        default:
            return 'black';
    }
};

const TableComponent = ({ token }) => {
    const [data, setData] = useState([]);
    const [decodedToken, setDecodedToken] = useState(null);
    const [type, setType] = useState('user');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpen1, setIsPopupOpen1] = useState(true);
    const itemsPerPage = 20;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = `https://www.aos.fit/api/getusers?page=${currentPage}`;
                let response = await fetch(apiUrl);
                const result = await response.json();
                setData(result.data);
                setTotalPages(result.pagination.totalPages)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const token = localStorage.getItem('token_aos');

        if (token) {
            const decodedToken = jwtDecode(token);
            setDecodedToken(decodedToken);
            if (decodedToken?.user?.type) setType(decodedToken?.user?.type)
        }
        fetchData()
    }, []);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = `https://www.aos.fit/api/getusers?page=${currentPage}`;
                let response = await fetch(apiUrl);
                const result = await response.json();
                setData(result.data);
                setTotalPages(result.pagination.totalPages)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (currentPage === 1) {

            fetchData();
            // Set up an interval to fetch data every 3 seconds
            const intervalId = setInterval(fetchData, 3000);

            // Clean up the interval on component unmount
            return () => clearInterval(intervalId);
        }
        fetchData();
    }, [currentPage]);

    const hidePop1 = () => {
        setIsPopupOpen1(false)

    }
    const showPop1 = () => {
        const fetchData = async () => {
            try {
                const apiUrl = `https://www.aos.fit/api/getusers?page=${currentPage}`;
                let response = await fetch(apiUrl);
                const result = await response.json();
                setData(result.data);
                setTotalPages(result.pagination.totalPages)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        setIsPopupOpen1(true)
    }

    const handleChange = (event) => {
        setCurrentPage(event.selected + 1);
        // setCurrentPage(event);
    };

    const handleClosePopUp = () => {
        setIsPopupOpen(false);
    };
    return (
        <div className="loc">
            <div className="inquires">
                <div className="all-inquires"> All Users</div>
            </div>
            <table className="custom-table">
                <thead >
                    <tr>
                        <th>User Name</th>
                        <th>Email</th>
                        <th>Creation Date</th>
                        <th>Access Type</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item._id}>
                            <td>{`${item.firstName} ${item.lastName}`}</td>
                            <td>{item.email}</td>
                            <td>{new Date(item.createdAt).toLocaleString('en-GB', {
                                timeZone: 'Africa/Cairo',
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false, // Use 24-hour format
                            })}</td>
                            <td style={{ color: getStatusColor(item.type) }}>{item.type?.charAt(0).toUpperCase() + item?.type?.slice(1)}</td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td onClick={hidePop1}>
                                <Popup showPop1={showPop1} data={item} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table >
            <br />
            <div className="bootstrap-pagination-container">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handleChange}
                    onClick={handleChange}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="<"
                    containerClassName='pagination'
                    pageLinkClassName='page-num'
                    previousLinkClassName='page-num'
                    nextLinkClassName='page-num'
                    activeClassName='active'
                />

            </div>
        </div >
    );
};

export default TableComponent;
