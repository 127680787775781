import { useState, React } from "react";
import Login from "../components/login/login";
import Images from "../components/images/images";
export default function LoginPage(props) {
    return (
        <>
            <div className="div-login-2">
                <Login />
                <Images />
            </div>   <style jsx>{`
            html {
                background-color: #F1F4F8;
            }
            .div-login-2 {
                margin-left: 10%;
                gap: 20px;
                display: flex;
            }
    
            @media (max-width: 991px) {
                .div-login-2 {
                    margin-left: 0%;
                    align-items: center;
                    flex-direction: column;
                    align-items: stretch;
                    gap: 0px;
                }
            }
    
      `}</style>
        </>
    );
}