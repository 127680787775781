import React, { useState, useEffect, useRef } from 'react';
import './filterDropdown.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const Dropdown = ({ setFilter, filter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [requestStartDate, setRequestStartDate] = React.useState(null);
  const [requestEndDate, setRequestEndDate] = React.useState(null);
  const [remainderStartDate, setRemainderStartDate] = React.useState(null);
  const [remainderEndDate, setRemainderEndDate] = React.useState(null);
  const [names, setNames] = React.useState(null);
  const [status, setStatus] = React.useState([]);
  const [location, setLocation] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [phone, setPhone] = useState(null);
  const [name, setName] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(`https://www.aos.fit/api/getusers`);
        const data = await response.json();
        const arrayOfFullNames = data.data.map(person => `${person.firstName} ${person.lastName}`);
        setNames(arrayOfFullNames);

      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
    fetchOptions();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleRequestStartDateChange = (date) => {
    setRequestStartDate(date);
  };
  const handleRequestEndDateChange = (date) => {
    setRequestEndDate(date);
  };

  const handleApply = () => {
    setFilter({
      remainderEndDate, remainderStartDate, requestStartDate, requestEndDate, employees, status, location, name, phone
    })
    setIsOpen(false);
  }

  const handleRemainderStartDateChange = (date) => {
    setRemainderStartDate(date);
  };

  const handleRemainderEndDateChange = (date) => {
    setRemainderEndDate(date);
  };


  const handleToggle = (name) => {
    if (!isOpen) {
      setIsOpen(true);
    }
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest('.optionsFilter') &&
      event.target.tagName.toLowerCase() !== 'li'
    ) {
      console.log(event.target)
      setIsOpen(false);
    }
  };


  return (
    <div className={isOpen ? "dropdown-container" : "dropdown-container2"} ref={dropdownRef}>
      <div className="header-table" onClick={handleToggle}>
        <img
          loading="lazy"
          src="filter.svg"
          className="img-filter"
          alt="Logo"
        />
        <div className="filter">Filter</div>
      </div>
      {isOpen && (
        <>
          <div className="optionsFilter">
            <div className="div-filter-3">Filter List </div>
            <div className="div-filter-27">
              <div className="div-filter-28">Name</div>
                <input
                  type="text"
                  placeholder="Enter Name here"
                  className="filter-input1"
                  value={name}
                  onChange={handleNameChange}
                  style={{ border: '1px solid #ccc', color: name ? 'black' : '#888' }}
                  />
            </div>
            <div className="div-filter-27">
              <div className="div-filter-28">Phone</div>
                <input
                  type="tel" 
                  pattern="^\+?[0-9]+$" 
                  placeholder="Enter phone here"
                  className="filter-input"
                  value={phone}
                  onChange={handlePhoneChange}
                  style={{ border: '1px solid #ccc', color: phone ? 'black' : '#888' }}
                  />
            </div>
            <div className="div-filter-4">
              <div className="div-filter-5">Employees</div>
            </div>
            <Autocomplete
              style={{ width: "100%" }}
              multiple
              id="tags-filled"
              options={names}
              defaultValue={filter.employees || []}
              onChange={(event, newValue) => setEmployees(newValue)}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                />
              )}
              onClick={(event) => event.stopPropagation()}
            />
            <div className="div-filter-27">
              <div className="div-filter-28">Request Date</div>
            </div>
            <div className="div-filter-29">
              <div className="div-filter-30">
                <div className="div-filter-31">Date From</div>
                <DatePicker
                  selected={requestStartDate}
                  onChange={handleRequestStartDateChange}
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Start Date"
                />
              </div>


              <div className="div-filter-34">
                <div className="div-filter-35">Date To</div>
                <DatePicker
                  selected={requestEndDate}
                  onChange={handleRequestEndDateChange}
                  selectsEnd
                  startDate={requestStartDate}
                  endDate={requestEndDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End Date"
                />
              </div>
            </div>
            <div className="div-filter-38">
              <div className="div-filter-41">Reminder Date</div>
              <div className="div-filter-29">
                <div className="div-filter-30">
                  <div className="div-filter-31">Date From</div>
                  <DatePicker
                    selected={remainderStartDate}
                    onChange={handleRemainderStartDateChange}
                    selectsStart
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                  />
                </div>
                <div className="div-filter-34">
                  <div className="div-filter-35">Date To</div>
                  <DatePicker
                    selected={remainderEndDate}
                    onChange={handleRemainderEndDateChange}
                    selectsEnd
                    startDate={remainderStartDate}
                    endDate={remainderEndDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                  />
                </div>
              </div>
              <div className="div-filter-45">Status</div>
            </div>
            <Autocomplete
              style={{ width: "100%" }}
              multiple
              id="tags-filled"
              options={['-', 'Following Up 1', 'Following Up 2', 'Following Up 3', 'Appointment Set', 'Done', 'Dead-End ']}
              freeSolo
              defaultValue={filter.status || []}
              onChange={(event, newValue) => setStatus(newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                />
              )}
            />
            <div className="div-filter-68">
              <div className="div-filter-69">Location</div>
            </div>
            <Autocomplete
              style={{ width: "100%", padding: "0px" }}
              multiple
              id="tags-filled"
              options={['Sheikh Zayed', '6 October', 'Mohandessin', 'Zamalek', 'Haram', 'Other']}
              freeSolo
              onChange={(event, newValue) => setLocation(newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                />
              )}
            />
            <div className="div-filter-80">
              <div className="div-filter-81" onClick={handleApply}>Apply All</div>
            </div>
          </div>
          <style jsx>{`
      .div {
        align-items: end;
        display: flex;
        flex-direction: column;
      }
      .optionsFilter {
        position: absolute;
        flex: 1;
        margin-top: 10px;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        border-radius: 0px 0px 8px 8px;
        padding: 10px;
      }
      .div-filter-3 {
        color: #00e;
        font-feature-settings: "clig" off, "liga" off;
        white-space: nowrap;
        font: 700 24px/28px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-3 {
          max-width: 100%;
          white-space: initial;
        }
      }
      .div-filter-4 {
        display: flex;
        margin-top: 18px;
        align-items: start;
        justify-content: space-between;
        gap: 20px;
        padding: 0 2px;
      }
      @media (max-width: 991px) {
        .div-filter-4 {
          max-width: 100%;
          flex-wrap: wrap;
        }
      }
      .div-filter-5 {
        color: #36393e;
        font-feature-settings: "clig" off, "liga" off;
        margin-top: 6px;
        flex-grow: 1;
        white-space: nowrap;
        font: 500 18px/28px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-5 {
          white-space: initial;
        }
      }
      .img {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 24px;
        overflow: hidden;
        align-self: stretch;
        max-width: 100%;
      }
      .div-filter-6 {
        border-radius: 8px;
        background-color: #e8e8e8;
        display: flex;
        margin-top: 18px;
        justify-content: space-between;
        gap: 16px;
        padding: 8px;
      }
      @media (max-width: 991px) {
        .div-filter-6 {
          max-width: 100%;
          flex-wrap: wrap;
        }
      }

      .div-filter-27 {
        display: flex;
        margin-top: 16px;
        justify-content: space-between;
        gap: 20px;
        padding: 0 2px;
      }
      @media (max-width: 991px) {
        .div-filter-27 {
          max-width: 100%;
          flex-wrap: wrap;
        }
      }
      .div-filter-28 {
        color: #2f2f2f;
        letter-spacing: -0.48px;
        flex-grow: 1;
        white-space: nowrap;
        font: 600 16px/30px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-28 {
          white-space: initial;
        }
      }
      .img-4 {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 24px;
        overflow: hidden;
        align-self: start;
        max-width: 100%;
      }
      .div-filter-29 {
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }
      @media (max-width: 991px) {
        .div-filter-29 {
          max-width: 100%;
          flex-wrap: wrap;
        }
      }
      .div-filter-30 {
        display: flex;
        flex-grow: 1;
        flex-basis: 0%;
        padding-top: 10px;
        flex-direction: column;
      }
      .div-filter-31 {
        color: #00e;
        text-align: left;
        letter-spacing: -0.48px;
        white-space: nowrap;
        font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-31 {
          white-space: initial;
        }
      }
      .div-filter-32 {
        border-radius: 8px;
        border: 0.5px solid #36393e;
        display: flex;
        margin-top: 12px;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 8px 17px;
      }
      .div-filter-34 {
        display: flex;
        flex-grow: 1;
        flex-basis: 0%;
        padding-top: 10px;
        flex-direction: column;
      }
      .div-filter-35 {
        color: #00e;
        text-align: left;
        letter-spacing: -0.48px;
        white-space: nowrap;
        font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-35 {
          white-space: initial;
        }
      }
      .div-filter-36 {
        border-radius: 8px;
        border: 0.5px solid #36393e;
        display: flex;
        margin-top: 12px;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 8px 17px;
      }
      .div-filter-38 {
        margin-top: 16px;
      }
      @media (max-width: 991px) {
        .div-filter-38 {
          max-width: 100%;
        }
      }
      .div-filter-39 {
        gap: 20px;
        display: flex;
      }
      @media (max-width: 991px) {
        .div-filter-39 {
          flex-direction: column;
          align-items: stretch;
          gap: 0px;
        }
      }
      .column {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 50%;
        margin-left: 0px;
      }
      @media (max-width: 991px) {
        .column {
          width: 100%;
        }
      }
      .div-filter-40 {
        display: flex;
        flex-direction: column;
      }
      @media (max-width: 991px) {
        .div-filter-40 {
          margin-top: 20px;
        }
      }
      .div-filter-41 {
        color: #2f2f2f;
        letter-spacing: -0.48px;
        white-space: nowrap;
        font: 600 16px/30px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-41 {
          white-space: initial;
        }
      }
      .div-filter-42 {
        color: #00e;
        text-align: center;
        letter-spacing: -0.48px;
        white-space: nowrap;
        font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-42 {
          white-space: initial;
        }
      }
      .div-filter-43 {
        border-radius: 8px;
        border: 0.5px solid #36393e;
        display: flex;
        margin-top: 12px;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 8px 17px;
      }
      .div-filter-45 {
        color: #36393e;
        font-feature-settings: "clig" off, "liga" off;
        margin-top: 24px;
        white-space: nowrap;
        font: 500 18px/28px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-45 {
          white-space: initial;
        }
      }
      .column-2 {
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 50%;
        margin-left: 20px;
      }
      @media (max-width: 991px) {
        .column-2 {
          width: 100%;
        }
      }
      .div-filter-46 {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: end;
      }
      @media (max-width: 991px) {
        .div-filter-46 {
          margin-top: 22px;
        }
      }
      .img-5 {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 24px;
        overflow: hidden;
        max-width: 100%;
      }
      .div-filter-47 {
        color: #00e;
        text-align: center;
        letter-spacing: -0.48px;
        align-self: stretch;
        margin-top: 26px;
        white-space: nowrap;
        font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-47 {
          white-space: initial;
        }
      }
      .div-filter-48 {
        border-radius: 8px;
        border: 0.5px solid #36393e;
        align-self: stretch;
        display: flex;
        margin-top: 12px;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 8px 17px;
      }
      .img-6 {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 24px;
        overflow: hidden;
        margin-top: 18px;
        max-width: 100%;
      }
      .div-filter-50 {
        border-radius: 8px;
        background-color: #e8e8e8;
        display: flex;
        margin-top: 18px;
        justify-content: space-between;
        gap: 20px;
        padding: 8px;
      }
      @media (max-width: 991px) {
        .div-filter-50 {
          max-width: 100%;
          flex-wrap: wrap;
        }
      }

      .div-filter-69 {
        color: #36393e;
        font-feature-settings: "clig" off, "liga" off;
        flex-grow: 1;
        white-space: nowrap;
        margin: auto 0;
        font: 500 18px/28px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-69 {
          white-space: initial;
        }
      }
      .div-filter-70 {
        border-radius: 8px;
        background-color: #e8e8e8;
        display: flex;
        margin-top: 18px;
        align-items: flex-start;
        justify-content: space-between;
        gap: 6px;
        padding: 8px 10px 38px;
      }
      @media (max-width: 991px) {
        .div-filter-70 {
          max-width: 100%;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      .div-filter-80 {
        background-color: #fff;
        display: flex;
        margin-top: 16px;
        flex-direction: column;
        justify-content: center;
      }
      @media (max-width: 991px) {
        .div-filter-80 {
          max-width: 100%;
        }
      }
      .div-filter-81 {
        color: #f7fbfe;
        text-align: center;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: #00e;
        padding: 13px 60px;
        font: 600 16px/22px Cairo, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      @media (max-width: 991px) {
        .div-filter-81 {
          white-space: initial;
          max-width: 100%;
          padding: 0 20px;
        }
      }
      .date-container {
        display: flex;
      }
    `}</style>
        </>
      )
      }
    </div >
  );
};

export default Dropdown;
