import React from 'react';
import './userMessage.css';
import Popup from '../userPopup/userPopup'
const CustomButton = ({ onClick }) => (
    <div className="input-container1" onClick={onClick}>
        <div className="image-wrapper1">
            <img
                loading="lazy"
                src="plus.svg"
                className="image"
                alt="User Image"
            />
            <div className="title">Add User</div>
        </div>
    </div>
);


function ContactUsMessages({ onUpdateUserTable }) {
    const showData = async () => {
        console.log('showData');
        onUpdateUserTable()
    };

    return (
        <div className="main-container">
            <header className="header">User Management</header>
            <div className="form-container">
                <Popup showData={showData} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
            </div>
        </div>
    );
}

export default ContactUsMessages;
