import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Redirect
} from "react-router-dom";
import Contact from "./pages/contact";
import Login from "./pages/login";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />      
      <Route
        path="/contact"
        element={<Contact />}
      />
      {/* <Route
        path="/free-360-body-analysis"
        element={<Fitness />}
      />
      <Route
        path="/circuit-gym1"
        element={<Circuit />}
      />
      <Route
        path="/gym1"
        element={<Gym />}
      />
      <Route
        path="/cgwlp30"
        element={<GWLP />}
      />
      <Route
        path="/contact-us1"
        element={<Contact />}
      />
      <Route
        path="/concept-her1"
        element={<ConceptHer />}
      />
      <Route
        path="/shred-30"
        element={<Shred30 />}
      />
      <Route
        path="/thank-you-page1669060964003"
        element={<ThankYou />}
      />
      <Route path="*"
      element={<NotFoundPage />}
      />
       */}
    </Routes>
    
  );
}
export default App;
